import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { SecondaryButton } from 'styles/styles';

import { SmallText, TicketNumberContainer } from '../styles';

export const Welcome = styled(SmallText)`
  font-size: ${({ theme }) => theme.fontSizes.big};
`;

export const Time = styled(Welcome)`
  font-weight: bold;
`;

export const UpgradeContainer = styled(TicketNumberContainer)`
  border: unset;
  background-color: ${({ theme }) => theme.palette.custom.upgradeBlock.background};
  position: relative;
`;

export const UpgradeBackgroundImage = styled.div`
  position: absolute;
  height: 90%;
  bottom: 0;
`;

export const UpgradeBackgroundIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.palette.custom.upgradeBlock.arrow};
  font-size: 13em;
  position: relative;
  bottom: 0.05em;
`;

export const SavedTime = styled(SmallText)`
  font-weight: bold;
  font-size: ${({ theme }) => theme.fontSizes.bigger};
  color: ${({ theme }) => theme.palette.custom.upgradeBlock.contrastText};
  z-index: 1;
`;

export const UpgradeFor = styled(SavedTime)`
  font-weight: normal;
  font-size: ${({ theme }) => theme.fontSizes.big};
  ${({ theme }) => theme.palette.custom.upgradeBlock.contrastText};
`;

export const Price = styled.span`
  font-weight: bold;
  color: ${({ theme }) => theme.palette.custom.upgradeBlock.contrastText};
  font-size: ${({ theme }) => theme.fontSizes.biggest};
  z-index: 1;
`;

export const UpgradeButton = styled(SecondaryButton)`
  z-index: 1;
  display: flex;
  box-shadow: 1px 1px 0 1px ${({ theme }) => theme.palette.custom.secondaryAccent.main};
  margin-top: 1.5em;
  margin-bottom: 0.8em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
`;

export const UpgradeButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5em;
`;

export const UpgradeButtonIcon = styled(FontAwesomeIcon)`
  font-size: 2em;
`;

export const UpgradeSmallPrint = styled(SmallText)`
  color: ${({ theme }) => theme.palette.custom.upgradeBlock.contrastText};
  z-index: 1;
`;

export const Spacer = styled.div`
  flex: 1;
`;
