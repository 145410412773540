import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import QRCodeScan from 'assets/images/qr-code-scan.png';
import Header from 'components/Layout/components/Header/Header';
import cloudinaryClient from 'lib/cloudinary/CloudinaryClient';
import { useGetEventQuery } from 'lib/graphql/graphql';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { EventLogo, RoundedContainedButton } from 'styles/styles';
import { useLocation, useParams } from 'wouter';

import {
  Circle,
  CircleContainer,
  CircleText,
  Container,
  Content,
  Image,
  Logo,
  Text,
  Title,
} from './styles';

function Home() {
  const { eventId = '' } = useParams();
  const [, setLocation] = useLocation();

  const { data, loading } = useGetEventQuery({ variables: { id: eventId! }, skip: !eventId });
  const event = data?.getEvent;

  const onNext = () => {
    if (eventId) setLocation('/tickets');
  };

  useEffect(() => {
    if (!loading && !event) {
      setLocation('/');
    }
  }, [event, loading, setLocation]);

  const eventImage = cloudinaryClient.image(event?.image as string);

  return (
    <Container>
      {!eventId && (<Header showLogo={false} />)}
      <Content>
        <Logo onClick={() => window.open('https://movon.vip/', '_blank')} />
        <EventLogo cldImg={eventImage} />
        <CircleContainer>
          <Circle />
          <CircleText>
            {eventId && (
              <Title>
                <FormattedMessage id="pdq_in_a_hurry" />
              </Title>
            )}
            <Text>
              <FormattedMessage
                id={eventId ? 'pdq_avoid_waiting_line' : 'use_camera_app_qr'}
                values={{
                  // eslint-disable-next-line react/no-unstable-nested-components
                  tag: (value) => <b>{value}</b>,
                }}
              />
            </Text>
            {!eventId && (
              <Image src={QRCodeScan} width="300px" />
            )}
            {eventId && (
              <RoundedContainedButton onClick={onNext}>
                <FontAwesomeIcon icon={faArrowRight} size="xl" color="primary" />
              </RoundedContainedButton>
            )}
          </CircleText>
        </CircleContainer>
      </Content>
    </Container>
  );
}

export default Home;
