import styled, { css } from 'styled-components';
import { BaseButton } from 'styles/styles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  width: 3rem;
`;

interface DateProps {
  $selected: boolean
  $hasSelection: boolean
}

export const Date = styled(BaseButton)<DateProps>`
  display: flex;
  flex-direction: column;
  background-color: transparent;
  font-weight: normal;
  font-size: ${({ theme }) => theme.fontSizes.normal};
  align-self: stretch;
  align-items: center;
  text-transform: uppercase;

  ${({ $selected }) => $selected && css`
    background-color: ${({ theme }) => theme.palette.secondary.main};
    color: ${({ theme }) => theme.palette.secondary.contrastText};
  `}

  ${({ $selected, $hasSelection }) => !$selected && $hasSelection && css`
    margin: -2px;
    border: 2px solid ${({ theme }) => theme.palette.secondary.main};
  `}

  :disabled {
    background-color: transparent;
    color: ${({ theme }) => theme.palette.custom.lightGray};
  }
`;

interface DotProps {
  $available: boolean
}

export const DayNumber = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.big};
  font-weight: bold;
`;

export const Dot = styled.span<DotProps>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.palette.custom.lightGray};

  ${({ $available }) => $available && css`
    background-color: ${({ theme }) => theme.palette.secondary.main};
  `}
`;
