import { skipToken } from '@apollo/client';
import WaveBackground from 'components/Layout/components/WaveBackground/WaveBackground';
import QRHolder from 'components/QRHolder/QRHolder';
import WhatsNext from 'components/WhatsNext/WhatsNext';
import {
  QueueType,
  useGetEventSuspenseQuery,
  useGetTicketFromTransactionSuspenseQuery,
  useGetWaitingLineSuspenseQuery,
} from 'lib/graphql/graphql';
import LanguageContext, { Languages } from 'lib/providers/Language/LanguageContext';
import { useContext, useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'wouter';

import FreemiumLayout from '../components/FreemiumLayout/FreemiumLayout';
import TicketNumber from '../components/TicketNumber/TicketNumber';
import { CenteredContainer, TitleText } from './styles';

export default function Called() {
  const { language } = useContext(LanguageContext);
  const [, setLocation] = useLocation();
  const transactionId = useMemo(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    return urlSearchParams.get('id');
  }, []);

  const { data } = useGetTicketFromTransactionSuspenseQuery(
    transactionId ? {
      variables: { transactionId },
      fetchPolicy: 'network-only',
      queryKey: 'get-transaction-called',
    } : skipToken,
  );
  const tickets = useMemo(() => data?.getTicketFromTransaction.tickets || [], [data]);
  const { displayId, eventId, waitingLineId } = tickets[0];

  const { data: waitingLineData } = useGetWaitingLineSuspenseQuery(
    waitingLineId ? {
      variables: { id: waitingLineId },
    } : skipToken,
  );

  const { data: eventData } = useGetEventSuspenseQuery(
    eventId ? {
      variables: { id: eventId },
    } : skipToken,
  );
  const event = eventData?.getEvent;
  const eventLanguage = (event?.languages[0] || 'en') as Languages;
  const waitingLine = waitingLineData?.getWaitingLine;
  const waitingLineDescription = waitingLine?.description?.[language] ?? waitingLine?.description?.[eventLanguage] ?? '';

  useEffect(() => {
    if (tickets && tickets.length > 0) {
      const ticket = tickets[0];
      if (ticket.queueType !== QueueType.Called && ticket.queueType !== QueueType.NoShow) {
        setLocation(`/wait?id=${transactionId}`);
      }
    }
  }, [tickets, setLocation, transactionId]);

  return (
    <>
      <WaveBackground />
      <FreemiumLayout>
        <CenteredContainer>
          <TitleText>
            <FormattedMessage id="waiting_line_called" />
          </TitleText>
        </CenteredContainer>
        <TicketNumber ticketNumber={displayId.toString()} />
        <QRHolder
          event={event!}
          waitingLine={waitingLine!}
          tickets={[tickets[0]]}
          transactionId={transactionId}
          displayWaitingLineInfos={false}
        />
        {waitingLineDescription && (
          <CenteredContainer>
            <WhatsNext waitingLineDescription={waitingLineDescription} />
          </CenteredContainer>
        )}
      </FreemiumLayout>
    </>
  );
}
