import darkMovOnVIP from 'assets/images/logo/movon-couleur-horizontal-small-no-bkg.png';
import MovOnVIP from 'assets/images/logo/movon-tag-horizontal-small.png';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100svh;
  overflow: hidden;
  background-color: ${({ theme }) => theme.palette.background.default};
  color: ${({ theme }) => theme.palette.primary.contrastText};
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
  align-items: center;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  flex-grow: 1;
  margin-bottom: 32px;
`;

export const Image = styled.img.attrs({
  alt: '',
})`
  user-select: none;
`;

export const Logos = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Logo = styled(Image).attrs(({ theme }) => ({
  alt: 'MovOnVIP',
  src: theme.palette.mode === 'dark' ? darkMovOnVIP : MovOnVIP,
}))`
  height: 80px;
  object-fit: contain;
  cursor: pointer;
`;

export const Title = styled.h1`
  margin: 0;
  font-size: ${({ theme }) => theme.fontSizes.bigger};
  font-weight: 800;
  line-height: normal;
  text-transform: uppercase;
`;

export const Text = styled.span`
  margin: 0;
  text-overflow: ellipsis;
  font-size: ${({ theme }) => theme.fontSizes.bigger};
`;

export const CircleText = styled.div`
  color: ${({ theme }) => theme.palette.custom.secondaryAccent.contrastText};
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 50svh;
  width: 50svh;
  padding-left: 6svh;
  padding-right: 10svh;
  z-index: 1;
  position: relative;
  right: 1em;
`;

export const Circle = styled.div`
  background-color: ${({ theme }) => theme.palette.custom.secondaryAccent.main};
  height: 50svh;
  width: 50svh;
  border-radius: 50%;
  padding: 7svh;
  position: absolute;
  right: 10svh;
`;

export const CircleContainer = styled.div`
  position: relative;
`;
