import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid } from '@mui/material';
import LanguageContext from 'lib/providers/Language/LanguageContext';
import settings from 'lib/settings';
import LegalAgreements from 'modules/LegalAgreements/LegalAgreements';
import { useContext, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { SmallPrimaryButton } from 'styles/styles';

import {
  BigStyledDialog,
  ButtonsHolder,
  FooterContainer,
  FooterText,
  FooterWrapper,
  SeparatingDot,
} from './styles';

export interface FooterProps {
  isHome: boolean;
}

function Footer({ isHome }: FooterProps) {
  const supportURL = settings.support;
  const { language } = useContext(LanguageContext);

  const [title, setTitle] = useState('');
  const [modalOpen, setModalOpen] = useState(false);

  const handleSelect = (selection: string) => {
    setTitle(selection);
    setModalOpen(true);
  };

  const handleSupport = () => {
    window.open(supportURL[language], '_blank');
  };

  return (
    <FooterWrapper $isHome={isHome}>
      <FooterContainer>
        <SmallPrimaryButton onClick={handleSupport}>
          <Grid container alignItems="center" gap={1}>
            <FontAwesomeIcon icon={faQuestionCircle} />
            <FormattedMessage id="pass_help" />
          </Grid>
        </SmallPrimaryButton>
        <ButtonsHolder>
          <FooterText onClick={() => handleSelect('terms_conditions')}>
            <FormattedMessage id="terms_conditions" />
          </FooterText>
          <SeparatingDot />
          <FooterText onClick={() => handleSelect('privacy_policy')}>
            <FormattedMessage id="privacy_policy" />
          </FooterText>
        </ButtonsHolder>
      </FooterContainer>
      <BigStyledDialog
        open={modalOpen}
        onClose={() => setModalOpen(false)}
      >
        <LegalAgreements
          title={title}
          onClose={() => setModalOpen(false)}
        />
      </BigStyledDialog>
    </FooterWrapper>
  );
}

export default Footer;
