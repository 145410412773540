import { useTheme } from '@mui/material';
import darkLogo from 'assets/images/logo/movon-couleur-horizontal-small-no-bkg.png';
import lightLogo from 'assets/images/logo/movon-tag-horizontal-small.png';
import LanguageSelector from 'components/LanguageSelector/LanguageSelector';

import {
  HeaderContainer,
  HeaderTitle,
  HeaderTop,
  Logo,
} from './styles';

interface HeaderProps {
  title?: React.ReactNode
  showLogo?: boolean
}

function Header(props: HeaderProps) {
  const { title, showLogo = true } = props;
  const theme = useTheme();

  return (
    <HeaderContainer>
      <HeaderTop>
        <Logo
          src={theme.palette.mode === 'dark' ? darkLogo : lightLogo}
          onClick={() => window.open('https://movon.vip/', '_blank')}
          $hidden={!showLogo}
        />
        <LanguageSelector />
      </HeaderTop>
      {title && (
        <HeaderTitle>
          {title}
        </HeaderTitle>
      )}
    </HeaderContainer>
  );
}

export default Header;
