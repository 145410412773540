import { Button } from '@mui/material';
import styled from 'styled-components';

export const QRSection = styled.div`
  background: ${({ theme }) => theme.palette.background.paper};
  border-radius: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 24px;
  gap: 1rem;
`;

export const QRTitle = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.big};
  font-weight: bold;
  color: black;
`;

export const QRSubTitle = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.normal};
  font-weight: 600;
  color: black;
  margin-top: -1rem;
`;

export const AddToWalletButton = styled(Button).attrs({
  disableRipple: true,
  disableFocusRipple: true,
  target: '_blank',
})`
  padding: 0;

  &:hover {
    background: transparent;
  }

  @media print {
    display: none;
  }
`;

export const AddToWalletImage = styled.img`
  max-width: 80vw;
  width: 100%;
  height: auto;
`;
