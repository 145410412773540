import battleshipGray from './themes/battleshipGray';
import blackOlive from './themes/blackOlive';
import earthYellow from './themes/earthYellow';
import lavenderBlush from './themes/lavenderBlush';
import midnightBlue from './themes/midnightBlue';
import raisinBlack from './themes/raisinBlack';
import { AppTheme } from './types/AppTheme';

const appThemes: AppTheme[] = [
  raisinBlack,
  earthYellow,
  lavenderBlush,
  midnightBlue,
  battleshipGray,
  blackOlive,
];

export const appThemesMap: Record<string, AppTheme> = appThemes.reduce((acc, theme) => (
  { ...acc, [theme.name]: theme }
), {});

export const defaultTheme = raisinBlack;

export default appThemes;
