import { createContext } from 'react';

export interface IPreviewContext {
  isPreview: boolean;
  customTheme?: string;
}

export const DefaultPreviewContext: IPreviewContext = {
  isPreview: false,
};

export const PreviewContext = createContext(DefaultPreviewContext);
