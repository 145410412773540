import {
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { AppThemeContext } from 'styles/AppThemeContext';
import { useSearch } from 'wouter';

import {
  DefaultPreviewContext,
  IPreviewContext,
  PreviewContext,
} from './PreviewContext';

interface PreviewProviderProps {
  children: JSX.Element
}

function PreviewProvider(props: PreviewProviderProps) {
  const { children } = props;
  const searchString = useSearch();
  const [isPreview, setIsPreview] = useState<boolean>(false);
  const [customTheme, setCustomTheme] = useState<string | undefined>(undefined);
  const { setTheme } = useContext(AppThemeContext);

  useEffect(() => {
    const params = new URLSearchParams(searchString);
    setIsPreview(params.has('preview'));
    const theme = params.get('theme');
    if (theme) {
      setCustomTheme(theme);
    } else {
      setCustomTheme(undefined);
    }
  }, [searchString]);

  const contextValue = useMemo<IPreviewContext>(() => {
    if (isPreview) {
      return {
        isPreview,
        customTheme,
      };
    }

    return DefaultPreviewContext;
  }, [isPreview, customTheme]);

  useEffect(() => {
    if (isPreview && customTheme) {
      setTheme(customTheme);
    }
  }, [isPreview, customTheme, setTheme]);

  return (
    <PreviewContext.Provider value={contextValue}>
      {children}
    </PreviewContext.Provider>
  );
}

export default PreviewProvider;
