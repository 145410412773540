import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  JSON: { input: any; output: any; }
  UUID: { input: string; output: string; }
  Void: { input: void; output: void; }
};

export type AuthPayload = {
  __typename?: 'AuthPayload';
  authToken: Scalars['String']['output'];
  user: User;
};

export type Cause = {
  __typename?: 'Cause';
  hasObnls?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type ClaimFreeTicketsInput = {
  contactInfo: ContactInfoInput;
  eventId: Scalars['UUID']['input'];
  metadata?: InputMaybe<Scalars['JSON']['input']>;
  quantity: Scalars['Int']['input'];
  waitingLineId: Scalars['UUID']['input'];
};

export enum CommunicationChannel {
  Email = 'EMAIL',
  Sms = 'SMS'
}

export type ContactInfo = {
  __typename?: 'ContactInfo';
  optInNotifications: Scalars['Boolean']['output'];
  preferredLocale: Scalars['String']['output'];
  type: ContactInfoType;
  value?: Maybe<Scalars['String']['output']>;
};

export type ContactInfoInput = {
  optInNotifications: Scalars['Boolean']['input'];
  preferredLocale: Scalars['String']['input'];
  type: ContactInfoType;
  value: Scalars['String']['input'];
};

export enum ContactInfoType {
  Email = 'EMAIL',
  Phone = 'PHONE'
}

export type CreateOrganizationResponse = {
  __typename?: 'CreateOrganizationResponse';
  invitationId: Scalars['String']['output'];
  organization: Organization;
};

export enum Device {
  Apple = 'APPLE',
  Google = 'GOOGLE'
}

export type DisplayTicket = {
  __typename?: 'DisplayTicket';
  displayId: Scalars['Int']['output'];
  id: Scalars['UUID']['output'];
  status: TicketStatus;
};

export type Event = {
  __typename?: 'Event';
  allowObnlChoice: Scalars['Boolean']['output'];
  description?: Maybe<NullableText>;
  endTime: Scalars['String']['output'];
  id: Scalars['String']['output'];
  image?: Maybe<Scalars['String']['output']>;
  languages: Array<Scalars['String']['output']>;
  location: Scalars['String']['output'];
  name: NullableText;
  organizationId: Scalars['String']['output'];
  shortUrl?: Maybe<Scalars['String']['output']>;
  startTime: Scalars['String']['output'];
  status: EventStatus;
  theme?: Maybe<Scalars['String']['output']>;
  timezone: Scalars['String']['output'];
  userId: Scalars['String']['output'];
};

export type EventCreationInput = {
  description?: InputMaybe<NullableTextInput>;
  endTime: Scalars['String']['input'];
  image: Scalars['String']['input'];
  languages: Array<Scalars['String']['input']>;
  location: Scalars['String']['input'];
  name: NullableTextInput;
  organizationId: Scalars['String']['input'];
  shortUrl?: InputMaybe<Scalars['String']['input']>;
  startTime: Scalars['String']['input'];
  status?: InputMaybe<EventStatus>;
  timezone: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type EventObnl = {
  __typename?: 'EventObnl';
  eventId: Scalars['String']['output'];
  obnlId: Scalars['String']['output'];
};

export type EventRole = {
  __typename?: 'EventRole';
  eventId: Scalars['String']['output'];
  role: RoleEnum;
};

export type EventRoleInput = {
  eventId: Scalars['String']['input'];
  role: RoleEnum;
};

export enum EventStatus {
  Draft = 'DRAFT',
  Hidden = 'HIDDEN',
  Published = 'PUBLISHED'
}

export type EventUpdateInput = {
  allowObnlChoice?: InputMaybe<Scalars['Boolean']['input']>;
  description?: InputMaybe<NullableTextInput>;
  endTime?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  languages?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  location?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<NullableTextInput>;
  shortUrl?: InputMaybe<Scalars['String']['input']>;
  startTime?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<EventStatus>;
  theme?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
};

export type GetSlugAvailabilityInput = {
  service?: InputMaybe<Scalars['String']['input']>;
  slug: Scalars['String']['input'];
};

export type Invitation = {
  __typename?: 'Invitation';
  events: Array<EventRole>;
  id: Scalars['UUID']['output'];
  invalidEmail?: Maybe<Scalars['Boolean']['output']>;
  organizationId: Scalars['String']['output'];
  status: InvitationStatusEnum;
  userEmail: Scalars['String']['output'];
  userId?: Maybe<Scalars['String']['output']>;
};

export type InvitationInput = {
  event: EventRoleInput;
  language: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
  status?: InputMaybe<InvitationStatusEnum>;
  userEmail: Scalars['String']['input'];
};

export enum InvitationStatusEnum {
  Accepted = 'ACCEPTED',
  Pending = 'PENDING',
  Rejected = 'REJECTED',
  Revoked = 'REVOKED'
}

export type LinkInviteReturn = {
  __typename?: 'LinkInviteReturn';
  eventId: Scalars['String']['output'];
  invitationId: Scalars['String']['output'];
  isPublished: Scalars['Boolean']['output'];
  organizationId: Scalars['String']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  _empty?: Maybe<Scalars['String']['output']>;
  addObnlToEvent?: Maybe<Scalars['Void']['output']>;
  aseLogin?: Maybe<Scalars['String']['output']>;
  buyTickets: TicketBought;
  callTicket?: Maybe<Scalars['Void']['output']>;
  cancelTickets?: Maybe<Scalars['Void']['output']>;
  changeEventShowObnlChoice: Event;
  changeEventVisibility: Event;
  changePassword?: Maybe<Scalars['Void']['output']>;
  changeWaitingLineStatus?: Maybe<Scalars['Void']['output']>;
  claimFreeTickets: Scalars['UUID']['output'];
  createConnectionToken: StripeConnectionToken;
  createEvent: Event;
  createInvitation: Invitation;
  createOrganization: CreateOrganizationResponse;
  createPaymentIntent: Scalars['String']['output'];
  createUser: User;
  createWaitingLine: WaitingLine;
  createWebhook: Webhook;
  deleteEvent?: Maybe<Scalars['Void']['output']>;
  deleteInvitation?: Maybe<Scalars['Void']['output']>;
  deleteWaitingLine?: Maybe<Scalars['Void']['output']>;
  deleteWebhook?: Maybe<Scalars['Void']['output']>;
  generatePass: Scalars['String']['output'];
  linkInvitationToUser: LinkInviteReturn;
  login: AuthPayload;
  loginMagicToken: AuthPayload;
  loginOrganization: AuthPayload;
  logout: Scalars['Boolean']['output'];
  moveTicketsToCalledQueue: Array<Ticket>;
  publishEvent: Event;
  refreshAuth: AuthPayload;
  releaseTimeSlot?: Maybe<Scalars['Void']['output']>;
  removeObnlFromEvent?: Maybe<Scalars['Void']['output']>;
  requestResetPassword?: Maybe<Scalars['Void']['output']>;
  resendWebhookCall?: Maybe<Scalars['Void']['output']>;
  reserveTickets: TicketCheckoutSession;
  reserveTimeSlot?: Maybe<Scalars['Void']['output']>;
  resetPassword?: Maybe<Scalars['Void']['output']>;
  sendMagicLoginLink?: Maybe<Scalars['Void']['output']>;
  sendNewEmailValidation: User;
  sendSupportMessage?: Maybe<Scalars['Void']['output']>;
  sendTickets?: Maybe<Scalars['Void']['output']>;
  updateEvent: Event;
  updateOrganization: Organization;
  updateWaitingLine: WaitingLine;
  updateWebhook: Webhook;
  validateEmail?: Maybe<Scalars['Void']['output']>;
  validateTicket: Scalars['Boolean']['output'];
};


export type MutationAddObnlToEventArgs = {
  eventId: Scalars['String']['input'];
  obnlId: Scalars['String']['input'];
};


export type MutationBuyTicketsArgs = {
  contactInfo?: InputMaybe<SessionContactInfo>;
  obnlId: Scalars['String']['input'];
  sessionToken: Scalars['String']['input'];
  transactionId?: InputMaybe<Scalars['UUID']['input']>;
};


export type MutationCallTicketArgs = {
  ticketId: Scalars['UUID']['input'];
  waitingLineId: Scalars['UUID']['input'];
};


export type MutationCancelTicketsArgs = {
  sessionToken: Scalars['String']['input'];
};


export type MutationChangeEventShowObnlChoiceArgs = {
  id: Scalars['String']['input'];
};


export type MutationChangeEventVisibilityArgs = {
  id: Scalars['String']['input'];
};


export type MutationChangePasswordArgs = {
  newPassword: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


export type MutationChangeWaitingLineStatusArgs = {
  eventId: Scalars['UUID']['input'];
  fields: UpdateWaitingLineStatusInput;
  id: Scalars['UUID']['input'];
};


export type MutationClaimFreeTicketsArgs = {
  fields: ClaimFreeTicketsInput;
};


export type MutationCreateEventArgs = {
  fields: EventCreationInput;
};


export type MutationCreateInvitationArgs = {
  fields: InvitationInput;
};


export type MutationCreateOrganizationArgs = {
  fields: OrganizationInput;
};


export type MutationCreatePaymentIntentArgs = {
  amount: Scalars['Int']['input'];
};


export type MutationCreateUserArgs = {
  email: Scalars['String']['input'];
  locale: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


export type MutationCreateWaitingLineArgs = {
  eventId: Scalars['UUID']['input'];
  fields: WaitingLineInput;
};


export type MutationCreateWebhookArgs = {
  eventId: Scalars['UUID']['input'];
  fields: WebhookInput;
};


export type MutationDeleteEventArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteInvitationArgs = {
  eventId: Scalars['String']['input'];
  id: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
};


export type MutationDeleteWaitingLineArgs = {
  eventId: Scalars['UUID']['input'];
  id: Scalars['UUID']['input'];
};


export type MutationDeleteWebhookArgs = {
  eventId: Scalars['UUID']['input'];
  id: Scalars['UUID']['input'];
};


export type MutationGeneratePassArgs = {
  device: Device;
  ticket: TicketInput;
  transactionId: Scalars['String']['input'];
};


export type MutationLinkInvitationToUserArgs = {
  token: Scalars['UUID']['input'];
};


export type MutationLoginArgs = {
  invitationToken?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
  username: Scalars['String']['input'];
};


export type MutationLoginMagicTokenArgs = {
  token: Scalars['String']['input'];
};


export type MutationLoginOrganizationArgs = {
  invitationId: Scalars['String']['input'];
};


export type MutationMoveTicketsToCalledQueueArgs = {
  id: Scalars['UUID']['input'];
};


export type MutationPublishEventArgs = {
  id: Scalars['String']['input'];
};


export type MutationRefreshAuthArgs = {
  invitationId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationReleaseTimeSlotArgs = {
  checkoutSession: Scalars['String']['input'];
};


export type MutationRemoveObnlFromEventArgs = {
  eventId: Scalars['String']['input'];
  obnlId: Scalars['String']['input'];
};


export type MutationRequestResetPasswordArgs = {
  locale: Scalars['String']['input'];
  username: Scalars['String']['input'];
};


export type MutationResendWebhookCallArgs = {
  eventId: Scalars['UUID']['input'];
  id: Scalars['UUID']['input'];
  webhookId: Scalars['UUID']['input'];
};


export type MutationReserveTicketsArgs = {
  fields: TicketsReservationInput;
};


export type MutationReserveTimeSlotArgs = {
  checkoutSession: Scalars['String']['input'];
  slotId: Scalars['UUID']['input'];
};


export type MutationResetPasswordArgs = {
  newPassword: Scalars['String']['input'];
  token: Scalars['String']['input'];
};


export type MutationSendMagicLoginLinkArgs = {
  channel: CommunicationChannel;
  id: Scalars['String']['input'];
};


export type MutationSendNewEmailValidationArgs = {
  locale: Scalars['String']['input'];
};


export type MutationSendSupportMessageArgs = {
  fields: SupportInput;
};


export type MutationSendTicketsArgs = {
  transactionId: Scalars['String']['input'];
};


export type MutationUpdateEventArgs = {
  fields: EventUpdateInput;
  id: Scalars['String']['input'];
};


export type MutationUpdateOrganizationArgs = {
  fields: OrganizationInput;
  id: Scalars['String']['input'];
};


export type MutationUpdateWaitingLineArgs = {
  eventId: Scalars['UUID']['input'];
  fields: WaitingLineUpdateInput;
  id: Scalars['UUID']['input'];
};


export type MutationUpdateWebhookArgs = {
  eventId: Scalars['UUID']['input'];
  fields: WebhookInput;
  id: Scalars['UUID']['input'];
};


export type MutationValidateEmailArgs = {
  token: Scalars['String']['input'];
};


export type MutationValidateTicketArgs = {
  ticketId: Scalars['UUID']['input'];
  waitingLineId: Scalars['UUID']['input'];
};

export type NullableText = {
  __typename?: 'NullableText';
  en?: Maybe<Scalars['String']['output']>;
  es?: Maybe<Scalars['String']['output']>;
  fr?: Maybe<Scalars['String']['output']>;
};

export type NullableTextInput = {
  en?: InputMaybe<Scalars['String']['input']>;
  es?: InputMaybe<Scalars['String']['input']>;
  fr?: InputMaybe<Scalars['String']['input']>;
};

export type Obnl = {
  __typename?: 'Obnl';
  cause: Scalars['String']['output'];
  description?: Maybe<NullableText>;
  id: Scalars['String']['output'];
  logo?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

export type ObnlInput = {
  cause: Scalars['String']['input'];
  description?: InputMaybe<NullableTextInput>;
  logo?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type Organization = {
  __typename?: 'Organization';
  description?: Maybe<Scalars['String']['output']>;
  gstId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  location?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  qstId?: Maybe<Scalars['String']['output']>;
  stripeAccountId: Scalars['String']['output'];
  stripeAccountLinked: Scalars['Boolean']['output'];
  validatedByMovon: Scalars['Boolean']['output'];
};

export type OrganizationDetails = {
  __typename?: 'OrganizationDetails';
  description?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

export type OrganizationInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  gstId?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  qstId?: InputMaybe<Scalars['String']['input']>;
};

export type PaginatedCauses = {
  __typename?: 'PaginatedCauses';
  causes: Array<Cause>;
  lastEvaluated?: Maybe<Scalars['JSON']['output']>;
};

export type PaginatedDisplayTickets = {
  __typename?: 'PaginatedDisplayTickets';
  lastEvaluated?: Maybe<Scalars['JSON']['output']>;
  tickets: Array<DisplayTicket>;
};

export type PaginatedEventObnls = {
  __typename?: 'PaginatedEventObnls';
  lastEvaluated?: Maybe<Scalars['JSON']['output']>;
  obnls: Array<Scalars['String']['output']>;
};

export type PaginatedEvents = {
  __typename?: 'PaginatedEvents';
  events: Array<Event>;
  lastEvaluated?: Maybe<Scalars['JSON']['output']>;
};

export type PaginatedInvitations = {
  __typename?: 'PaginatedInvitations';
  invitations: Array<Invitation>;
  lastEvaluated?: Maybe<Scalars['JSON']['output']>;
};

export type PaginatedObnls = {
  __typename?: 'PaginatedObnls';
  lastEvaluated?: Maybe<Scalars['JSON']['output']>;
  obnls: Array<Obnl>;
};

export type PaginatedOrganizations = {
  __typename?: 'PaginatedOrganizations';
  lastEvaluated?: Maybe<Scalars['JSON']['output']>;
  organizations: Array<Organization>;
};

export type PaginatedPendingTransfers = {
  __typename?: 'PaginatedPendingTransfers';
  lastEvaluated?: Maybe<Scalars['JSON']['output']>;
  pendingTransfers: Array<PendingTransfer>;
};

export type PaginatedTickets = {
  __typename?: 'PaginatedTickets';
  lastEvaluated?: Maybe<Scalars['JSON']['output']>;
  tickets: Array<Ticket>;
};

export type PaginatedTimeSlots = {
  __typename?: 'PaginatedTimeSlots';
  lastEvaluated?: Maybe<Scalars['JSON']['output']>;
  timeSlots: Array<TimeSlot>;
};

export type PaginatedWaitingLines = {
  __typename?: 'PaginatedWaitingLines';
  lastEvaluated?: Maybe<Scalars['JSON']['output']>;
  waitingLines: Array<WaitingLine>;
};

export type PaginatedWebhookCalls = {
  __typename?: 'PaginatedWebhookCalls';
  lastEvaluated?: Maybe<Scalars['JSON']['output']>;
  webhookCalls: Array<WebhookCall>;
};

export type PaginatedWebhooks = {
  __typename?: 'PaginatedWebhooks';
  lastEvaluated?: Maybe<Scalars['JSON']['output']>;
  webhooks: Array<Webhook>;
};

export type PendingTransfer = {
  __typename?: 'PendingTransfer';
  chargeId: Scalars['String']['output'];
  eventId: Scalars['String']['output'];
  obnlId: Scalars['String']['output'];
  organizationId: Scalars['String']['output'];
  transactionId: Scalars['String']['output'];
  transferId: Scalars['String']['output'];
  waitingLineId: Scalars['String']['output'];
};

export type Query = {
  __typename?: 'Query';
  _empty?: Maybe<Scalars['String']['output']>;
  getAllAcceptedInvitationsForUser: PaginatedInvitations;
  getAllCalledWaitingLineTickets: PaginatedDisplayTickets;
  getAllCauses: PaginatedCauses;
  getAllEventWaitingLines: PaginatedWaitingLines;
  getAllEventWebhooks: PaginatedWebhooks;
  getAllEventsForOrganization: PaginatedEvents;
  getAllEventsForUser: PaginatedEvents;
  getAllInvitationsForEvent: PaginatedInvitations;
  getAllInvitationsForOrganization: PaginatedInvitations;
  getAllInvitationsForUser: PaginatedInvitations;
  getAllNoShowWaitingLineTickets: PaginatedDisplayTickets;
  getAllObnls: PaginatedObnls;
  getAllObnlsByCause: PaginatedObnls;
  getAllObnlsIdsForEvent: PaginatedEventObnls;
  getAllOrganizationsForUser: PaginatedOrganizations;
  getAllWaitingLineTickets: PaginatedTickets;
  getAllWaitingLineTimeSlots: PaginatedTimeSlots;
  getAllWebhookCalls: PaginatedWebhookCalls;
  getCause?: Maybe<Cause>;
  getEvent?: Maybe<Event>;
  getEventStripeTransactions?: Maybe<Scalars['JSON']['output']>;
  getInvitation?: Maybe<Invitation>;
  getInvitationById?: Maybe<Invitation>;
  getObnl?: Maybe<Obnl>;
  getOrganization?: Maybe<Organization>;
  getOrganizationAccountLink?: Maybe<Scalars['String']['output']>;
  getOrganizationDetails?: Maybe<OrganizationDetails>;
  getOrganizationStripeAccount?: Maybe<Scalars['JSON']['output']>;
  getOrganizationStripeBalance?: Maybe<Scalars['JSON']['output']>;
  getOrganizationStripeDashboardLink?: Maybe<Scalars['String']['output']>;
  getOrganizationStripePayouts?: Maybe<Scalars['JSON']['output']>;
  getSlugAvailability: Scalars['Boolean']['output'];
  getTicket: Ticket;
  getTicketFromTransaction: PaginatedTickets;
  getTicketWaitTime: Scalars['Int']['output'];
  getUser?: Maybe<User>;
  getWaitingLine?: Maybe<WaitingLine>;
  getWebhook: Webhook;
  getWebhookCall: WebhookCall;
  hasOrganizations: Scalars['Boolean']['output'];
};


export type QueryGetAllAcceptedInvitationsForUserArgs = {
  start?: InputMaybe<Scalars['JSON']['input']>;
};


export type QueryGetAllCalledWaitingLineTicketsArgs = {
  start?: InputMaybe<Scalars['JSON']['input']>;
  waitingLineId: Scalars['UUID']['input'];
};


export type QueryGetAllCausesArgs = {
  start?: InputMaybe<Scalars['JSON']['input']>;
};


export type QueryGetAllEventWaitingLinesArgs = {
  eventId: Scalars['UUID']['input'];
  start?: InputMaybe<Scalars['JSON']['input']>;
};


export type QueryGetAllEventWebhooksArgs = {
  eventId: Scalars['UUID']['input'];
  start?: InputMaybe<Scalars['JSON']['input']>;
};


export type QueryGetAllEventsForOrganizationArgs = {
  organizationId: Scalars['String']['input'];
  start?: InputMaybe<Scalars['JSON']['input']>;
};


export type QueryGetAllEventsForUserArgs = {
  start?: InputMaybe<Scalars['JSON']['input']>;
};


export type QueryGetAllInvitationsForEventArgs = {
  eventId: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
  start?: InputMaybe<Scalars['JSON']['input']>;
};


export type QueryGetAllInvitationsForOrganizationArgs = {
  organizationId: Scalars['String']['input'];
  start?: InputMaybe<Scalars['JSON']['input']>;
};


export type QueryGetAllInvitationsForUserArgs = {
  start?: InputMaybe<Scalars['JSON']['input']>;
};


export type QueryGetAllNoShowWaitingLineTicketsArgs = {
  start?: InputMaybe<Scalars['JSON']['input']>;
  waitingLineId: Scalars['UUID']['input'];
};


export type QueryGetAllObnlsArgs = {
  start?: InputMaybe<Scalars['JSON']['input']>;
};


export type QueryGetAllObnlsByCauseArgs = {
  cause: Scalars['String']['input'];
  start?: InputMaybe<Scalars['JSON']['input']>;
};


export type QueryGetAllObnlsIdsForEventArgs = {
  eventId: Scalars['String']['input'];
  start?: InputMaybe<Scalars['JSON']['input']>;
};


export type QueryGetAllOrganizationsForUserArgs = {
  start?: InputMaybe<Scalars['JSON']['input']>;
};


export type QueryGetAllWaitingLineTicketsArgs = {
  start?: InputMaybe<Scalars['JSON']['input']>;
  waitingLineId: Scalars['UUID']['input'];
};


export type QueryGetAllWaitingLineTimeSlotsArgs = {
  start?: InputMaybe<Scalars['JSON']['input']>;
  waitingLineId: Scalars['UUID']['input'];
};


export type QueryGetAllWebhookCallsArgs = {
  eventId: Scalars['UUID']['input'];
  start?: InputMaybe<Scalars['JSON']['input']>;
  webhookId: Scalars['UUID']['input'];
};


export type QueryGetCauseArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetEventArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetEventStripeTransactionsArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetInvitationArgs = {
  id: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
};


export type QueryGetInvitationByIdArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetObnlArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetOrganizationAccountLinkArgs = {
  stripeAccountId: Scalars['String']['input'];
};


export type QueryGetOrganizationDetailsArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetOrganizationStripeAccountArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetOrganizationStripeBalanceArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetOrganizationStripeDashboardLinkArgs = {
  stripeAccountId: Scalars['String']['input'];
};


export type QueryGetOrganizationStripePayoutsArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetSlugAvailabilityArgs = {
  fields: GetSlugAvailabilityInput;
};


export type QueryGetTicketArgs = {
  ticketId: Scalars['UUID']['input'];
  waitingLineId: Scalars['UUID']['input'];
};


export type QueryGetTicketFromTransactionArgs = {
  transactionId: Scalars['String']['input'];
};


export type QueryGetTicketWaitTimeArgs = {
  ticketId: Scalars['UUID']['input'];
  waitingLineId: Scalars['UUID']['input'];
};


export type QueryGetWaitingLineArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryGetWebhookArgs = {
  eventId: Scalars['UUID']['input'];
  id: Scalars['UUID']['input'];
};


export type QueryGetWebhookCallArgs = {
  eventId: Scalars['UUID']['input'];
  id: Scalars['UUID']['input'];
  webhookId: Scalars['UUID']['input'];
};

export type Queue = {
  __typename?: 'Queue';
  depth: Scalars['Int']['output'];
};

export enum QueueType {
  Called = 'CALLED',
  Free = 'FREE',
  NoShow = 'NO_SHOW',
  Premium = 'PREMIUM'
}

export type Queueable = {
  queueItemId?: Maybe<Scalars['String']['output']>;
};

export enum RoleEnum {
  Admin = 'ADMIN',
  Staff = 'STAFF'
}

export type SessionContactInfo = {
  optInNotifications: Scalars['Boolean']['input'];
  preferredLocale: Scalars['String']['input'];
  type: ContactInfoType;
  value?: InputMaybe<Scalars['String']['input']>;
};

export enum SessionType {
  Freemium = 'Freemium',
  Payment = 'Payment',
  TimeSlot = 'TimeSlot'
}

export type StripeConnectionToken = {
  __typename?: 'StripeConnectionToken';
  location?: Maybe<Scalars['String']['output']>;
  secret: Scalars['String']['output'];
};

export type SupportInput = {
  email: Scalars['String']['input'];
  language: Scalars['String']['input'];
  message: Scalars['String']['input'];
  name: Scalars['String']['input'];
  subject: Scalars['String']['input'];
};

export type Text = {
  __typename?: 'Text';
  en: Scalars['String']['output'];
  es: Scalars['String']['output'];
  fr: Scalars['String']['output'];
};

export type TextInput = {
  en: Scalars['String']['input'];
  es: Scalars['String']['input'];
  fr: Scalars['String']['input'];
};

export type Ticket = {
  __typename?: 'Ticket';
  contactInfo?: Maybe<ContactInfo>;
  createdAt: Scalars['String']['output'];
  displayId: Scalars['Int']['output'];
  eventId: Scalars['UUID']['output'];
  expiresAt: Scalars['String']['output'];
  id: Scalars['UUID']['output'];
  metadata?: Maybe<Scalars['JSON']['output']>;
  obnlId?: Maybe<Scalars['String']['output']>;
  owner?: Maybe<Scalars['String']['output']>;
  queueType?: Maybe<QueueType>;
  status: TicketStatus;
  timeSlot?: Maybe<TicketTimeSlotInfos>;
  transactionId: Scalars['UUID']['output'];
  waitingLineId: Scalars['UUID']['output'];
};

export type TicketBought = {
  __typename?: 'TicketBought';
  clientSecret: Scalars['String']['output'];
  returnUrl: Scalars['String']['output'];
};

export type TicketCheckoutSession = {
  __typename?: 'TicketCheckoutSession';
  sessionToken: Scalars['String']['output'];
  timer?: Maybe<Scalars['String']['output']>;
};

export type TicketInput = {
  eventId: Scalars['UUID']['input'];
  ids: Array<Scalars['UUID']['input']>;
  waitingLineId: Scalars['UUID']['input'];
};

export type TicketQueueTypePayload = {
  __typename?: 'TicketQueueTypePayload';
  queueType: QueueType;
};

export enum TicketStatus {
  Activated = 'ACTIVATED',
  Cancelled = 'CANCELLED',
  Pending = 'PENDING',
  Refunded = 'REFUNDED',
  Reserved = 'RESERVED',
  Validated = 'VALIDATED'
}

export type TicketTimeSlotInfos = {
  __typename?: 'TicketTimeSlotInfos';
  endTime: Scalars['String']['output'];
  startTime: Scalars['String']['output'];
};

export type TicketsReservationInput = {
  consumerLocale?: InputMaybe<Scalars['String']['input']>;
  eventId: Scalars['UUID']['input'];
  metadata?: InputMaybe<Scalars['JSON']['input']>;
  quantity: Scalars['Int']['input'];
  transactionId?: InputMaybe<Scalars['String']['input']>;
  type: SessionType;
  waitingLineId: Scalars['UUID']['input'];
};

export type TimeSlot = {
  __typename?: 'TimeSlot';
  endTime: Scalars['String']['output'];
  id: Scalars['UUID']['output'];
  maxAmount: Scalars['Int']['output'];
  reservedAmount: Scalars['Int']['output'];
  startTime: Scalars['String']['output'];
  waitingLineId: Scalars['UUID']['output'];
};

export type TimeSlotOwner = {
  slotId?: Maybe<Scalars['UUID']['output']>;
  timeSlotItemId?: Maybe<Scalars['UUID']['output']>;
};

export type Transfer = {
  __typename?: 'Transfer';
  amount: Scalars['Float']['output'];
  currency: Scalars['String']['output'];
  destination: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type UpdateWaitingLineStatusInput = {
  message?: InputMaybe<NullableTextInput>;
  status: WaitingLineStatus;
};

export type User = {
  __typename?: 'User';
  channel?: Maybe<CommunicationChannel>;
  confirmedAccount: Scalars['Boolean']['output'];
  email: Scalars['String']['output'];
  id: Scalars['String']['output'];
};

export type WaitingLine = {
  __typename?: 'WaitingLine';
  activatedTickets: Scalars['Int']['output'];
  autoCall: Scalars['Boolean']['output'];
  callSize?: Maybe<Scalars['Int']['output']>;
  calledQueueWaitingTime: Scalars['Int']['output'];
  currency: Scalars['String']['output'];
  description: NullableText;
  eventId: Scalars['UUID']['output'];
  freeQueueWaitingTime: Scalars['Int']['output'];
  hasTimeSlots: Scalars['Boolean']['output'];
  id: Scalars['UUID']['output'];
  isFreemium: Scalars['Boolean']['output'];
  limitedQuantity: Scalars['Boolean']['output'];
  maxGroupSize: Scalars['Int']['output'];
  message?: Maybe<NullableText>;
  metadata: Queue;
  name: NullableText;
  noShowTimeout: Scalars['Int']['output'];
  paidTickets: Scalars['Int']['output'];
  premiumQueueWaitingTime: Scalars['Int']['output'];
  price: Scalars['Float']['output'];
  productDescription: NullableText;
  quantity?: Maybe<Scalars['Int']['output']>;
  statementDescriptor: Scalars['String']['output'];
  status: WaitingLineStatus;
  ticketsPerTimeSlot?: Maybe<Scalars['Int']['output']>;
  timeSlotDuration?: Maybe<Scalars['Int']['output']>;
  timeSlotEnd?: Maybe<Scalars['String']['output']>;
  timeSlotScheduleEnd?: Maybe<Scalars['String']['output']>;
  timeSlotScheduleStart?: Maybe<Scalars['String']['output']>;
  timeSlotStart?: Maybe<Scalars['String']['output']>;
  usedTickets: Scalars['Int']['output'];
  waitingLineType: WaitingLineType;
};

export type WaitingLineInput = {
  autoCall: Scalars['Boolean']['input'];
  callSize?: InputMaybe<Scalars['Int']['input']>;
  currency: Scalars['String']['input'];
  description: NullableTextInput;
  hasTimeSlots: Scalars['Boolean']['input'];
  isFreemium: Scalars['Boolean']['input'];
  limitedQuantity: Scalars['Boolean']['input'];
  maxGroupSize: Scalars['Int']['input'];
  name: NullableTextInput;
  noShowTimeout: Scalars['Int']['input'];
  price: Scalars['Float']['input'];
  productDescription: NullableTextInput;
  quantity?: InputMaybe<Scalars['Int']['input']>;
  statementDescriptor: Scalars['String']['input'];
  ticketsPerTimeSlot?: InputMaybe<Scalars['Int']['input']>;
  timeSlotDuration?: InputMaybe<Scalars['Int']['input']>;
  timeSlotEnd?: InputMaybe<Scalars['String']['input']>;
  timeSlotScheduleEnd?: InputMaybe<Scalars['String']['input']>;
  timeSlotScheduleStart?: InputMaybe<Scalars['String']['input']>;
  timeSlotStart?: InputMaybe<Scalars['String']['input']>;
  waitingLineType: WaitingLineType;
};

export enum WaitingLineStatus {
  Active = 'ACTIVE',
  Hidden = 'HIDDEN',
  Inactive = 'INACTIVE'
}

export enum WaitingLineType {
  Gold = 'GOLD',
  Silver = 'SILVER'
}

export type WaitingLineUpdateInput = {
  autoCall?: InputMaybe<Scalars['Boolean']['input']>;
  callSize?: InputMaybe<Scalars['Int']['input']>;
  currency?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<NullableTextInput>;
  hasTimeSlots: Scalars['Boolean']['input'];
  isFreemium: Scalars['Boolean']['input'];
  limitedQuantity?: InputMaybe<Scalars['Boolean']['input']>;
  maxGroupSize?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<NullableTextInput>;
  noShowTimeout?: InputMaybe<Scalars['Int']['input']>;
  price?: InputMaybe<Scalars['Float']['input']>;
  productDescription?: InputMaybe<NullableTextInput>;
  quantity?: InputMaybe<Scalars['Int']['input']>;
  statementDescriptor: Scalars['String']['input'];
  ticketsPerTimeSlot?: InputMaybe<Scalars['Int']['input']>;
  timeSlotDuration?: InputMaybe<Scalars['Int']['input']>;
  timeSlotEnd?: InputMaybe<Scalars['String']['input']>;
  timeSlotScheduleEnd?: InputMaybe<Scalars['String']['input']>;
  timeSlotScheduleStart?: InputMaybe<Scalars['String']['input']>;
  timeSlotStart?: InputMaybe<Scalars['String']['input']>;
  waitingLineType?: InputMaybe<WaitingLineType>;
};

export type Webhook = {
  __typename?: 'Webhook';
  actions: Array<WebhookAction>;
  description: Scalars['String']['output'];
  endpointUrl: Scalars['String']['output'];
  eventId: Scalars['UUID']['output'];
  id: Scalars['UUID']['output'];
  lastDaysMetadata?: Maybe<Array<Maybe<WebhookMetadata>>>;
  metadata?: Maybe<WebhookMetadata>;
};


export type WebhookLastDaysMetadataArgs = {
  days: Scalars['Int']['input'];
};

export enum WebhookAction {
  TicketCalled = 'TICKET_CALLED'
}

export type WebhookCall = {
  __typename?: 'WebhookCall';
  action: WebhookAction;
  createdAt: Scalars['String']['output'];
  eventId: Scalars['UUID']['output'];
  id: Scalars['UUID']['output'];
  payload: Scalars['JSON']['output'];
  response?: Maybe<Scalars['JSON']['output']>;
  statusCode?: Maybe<Scalars['Int']['output']>;
  webhookId: Scalars['UUID']['output'];
};

export type WebhookInput = {
  actions: Array<WebhookAction>;
  description: Scalars['String']['input'];
  endpointUrl: Scalars['String']['input'];
};

export type WebhookMetadata = {
  __typename?: 'WebhookMetadata';
  day: Scalars['String']['output'];
  errors: Scalars['Int']['output'];
  success: Scalars['Int']['output'];
  webhookId: Scalars['UUID']['output'];
};

export type SendMagicLoginLinkMutationVariables = Exact<{
  channel: CommunicationChannel;
  id: Scalars['String']['input'];
}>;


export type SendMagicLoginLinkMutation = { __typename?: 'Mutation', sendMagicLoginLink?: void | null };

export type AseLoginMutationVariables = Exact<{ [key: string]: never; }>;


export type AseLoginMutation = { __typename?: 'Mutation', aseLogin?: string | null };

export type LoginMagicTokenMutationVariables = Exact<{
  token: Scalars['String']['input'];
}>;


export type LoginMagicTokenMutation = { __typename?: 'Mutation', loginMagicToken: { __typename?: 'AuthPayload', authToken: string } };

export type RefreshAuthMutationVariables = Exact<{ [key: string]: never; }>;


export type RefreshAuthMutation = { __typename?: 'Mutation', refreshAuth: { __typename?: 'AuthPayload', authToken: string } };

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = { __typename?: 'Mutation', logout: boolean };

export type GetEventQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type GetEventQuery = { __typename?: 'Query', getEvent?: { __typename?: 'Event', id: string, userId: string, location: string, startTime: string, endTime: string, timezone: string, status: EventStatus, image?: string | null, languages: Array<string>, allowObnlChoice: boolean, theme?: string | null, name: { __typename?: 'NullableText', fr?: string | null, en?: string | null, es?: string | null }, description?: { __typename?: 'NullableText', fr?: string | null, en?: string | null, es?: string | null } | null } | null };

export type GetAllObnlsIdsForEventQueryVariables = Exact<{
  eventId: Scalars['String']['input'];
  start?: InputMaybe<Scalars['JSON']['input']>;
}>;


export type GetAllObnlsIdsForEventQuery = { __typename?: 'Query', getAllObnlsIdsForEvent: { __typename?: 'PaginatedEventObnls', obnls: Array<string>, lastEvaluated?: any | null } };

export type GetObnlQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type GetObnlQuery = { __typename?: 'Query', getObnl?: { __typename?: 'Obnl', id: string, name: string, cause: string, logo?: string | null, description?: { __typename?: 'NullableText', fr?: string | null, en?: string | null, es?: string | null } | null } | null };

export type SendSupportMessageMutationVariables = Exact<{
  fields: SupportInput;
}>;


export type SendSupportMessageMutation = { __typename?: 'Mutation', sendSupportMessage?: void | null };

export type ReserveTicketsMutationVariables = Exact<{
  fields: TicketsReservationInput;
}>;


export type ReserveTicketsMutation = { __typename?: 'Mutation', reserveTickets: { __typename?: 'TicketCheckoutSession', timer?: string | null, sessionToken: string } };

export type CancelTicketsMutationVariables = Exact<{
  sessionToken: Scalars['String']['input'];
}>;


export type CancelTicketsMutation = { __typename?: 'Mutation', cancelTickets?: void | null };

export type GeneratePassMutationVariables = Exact<{
  device: Device;
  ticket: TicketInput;
  transactionId: Scalars['String']['input'];
}>;


export type GeneratePassMutation = { __typename?: 'Mutation', generatePass: string };

export type BuyTicketsMutationVariables = Exact<{
  sessionToken: Scalars['String']['input'];
  contactInfo?: InputMaybe<SessionContactInfo>;
  transactionId?: InputMaybe<Scalars['UUID']['input']>;
  obnlId: Scalars['String']['input'];
}>;


export type BuyTicketsMutation = { __typename?: 'Mutation', buyTickets: { __typename?: 'TicketBought', clientSecret: string, returnUrl: string } };

export type SendTicketsMutationVariables = Exact<{
  transactionId: Scalars['String']['input'];
}>;


export type SendTicketsMutation = { __typename?: 'Mutation', sendTickets?: void | null };

export type GetTicketFromTransactionQueryVariables = Exact<{
  transactionId: Scalars['String']['input'];
}>;


export type GetTicketFromTransactionQuery = { __typename?: 'Query', getTicketFromTransaction: { __typename?: 'PaginatedTickets', tickets: Array<{ __typename?: 'Ticket', id: string, eventId: string, waitingLineId: string, status: TicketStatus, expiresAt: string, transactionId: string, owner?: string | null, displayId: number, obnlId?: string | null, queueType?: QueueType | null, contactInfo?: { __typename?: 'ContactInfo', type: ContactInfoType, value?: string | null } | null, timeSlot?: { __typename?: 'TicketTimeSlotInfos', startTime: string, endTime: string } | null }> } };

export type GetTicketWaitingTimeQueryVariables = Exact<{
  waitingLineId: Scalars['UUID']['input'];
  ticketId: Scalars['UUID']['input'];
}>;


export type GetTicketWaitingTimeQuery = { __typename?: 'Query', getTicketWaitTime: number };

export type ReserveTimeSlotMutationVariables = Exact<{
  checkoutSession: Scalars['String']['input'];
  slotId: Scalars['UUID']['input'];
}>;


export type ReserveTimeSlotMutation = { __typename?: 'Mutation', reserveTimeSlot?: void | null };

export type ReleaseTimeSlotMutationVariables = Exact<{
  checkoutSession: Scalars['String']['input'];
}>;


export type ReleaseTimeSlotMutation = { __typename?: 'Mutation', releaseTimeSlot?: void | null };

export type GetAllWaitingLineTimeSlotsQueryVariables = Exact<{
  waitingLineId: Scalars['UUID']['input'];
  start?: InputMaybe<Scalars['JSON']['input']>;
}>;


export type GetAllWaitingLineTimeSlotsQuery = { __typename?: 'Query', getAllWaitingLineTimeSlots: { __typename?: 'PaginatedTimeSlots', lastEvaluated?: any | null, timeSlots: Array<{ __typename?: 'TimeSlot', id: string, startTime: string, endTime: string, reservedAmount: number, maxAmount: number }> } };

export type ClaimFreeTicketsMutationVariables = Exact<{
  fields: ClaimFreeTicketsInput;
}>;


export type ClaimFreeTicketsMutation = { __typename?: 'Mutation', claimFreeTickets: string };

export type GetAllEventWaitingLinesQueryVariables = Exact<{
  eventId: Scalars['UUID']['input'];
  start?: InputMaybe<Scalars['JSON']['input']>;
}>;


export type GetAllEventWaitingLinesQuery = { __typename?: 'Query', getAllEventWaitingLines: { __typename?: 'PaginatedWaitingLines', lastEvaluated?: any | null, waitingLines: Array<{ __typename?: 'WaitingLine', id: string, status: WaitingLineStatus, waitingLineType: WaitingLineType, isFreemium: boolean, price: number, currency: string, maxGroupSize: number, quantity?: number | null, limitedQuantity: boolean, usedTickets: number, activatedTickets: number, hasTimeSlots: boolean, timeSlotStart?: string | null, name: { __typename?: 'NullableText', fr?: string | null, en?: string | null, es?: string | null }, description: { __typename?: 'NullableText', fr?: string | null, en?: string | null, es?: string | null }, productDescription: { __typename?: 'NullableText', fr?: string | null, en?: string | null, es?: string | null }, message?: { __typename?: 'NullableText', fr?: string | null, en?: string | null, es?: string | null } | null }> } };

export type GetWaitingLineQueryVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;


export type GetWaitingLineQuery = { __typename?: 'Query', getWaitingLine?: { __typename?: 'WaitingLine', id: string, status: WaitingLineStatus, waitingLineType: WaitingLineType, maxGroupSize: number, price: number, isFreemium: boolean, currency: string, quantity?: number | null, limitedQuantity: boolean, usedTickets: number, activatedTickets: number, hasTimeSlots: boolean, timeSlotStart?: string | null, name: { __typename?: 'NullableText', fr?: string | null, en?: string | null, es?: string | null }, description: { __typename?: 'NullableText', fr?: string | null, en?: string | null, es?: string | null }, productDescription: { __typename?: 'NullableText', fr?: string | null, en?: string | null, es?: string | null } } | null };

export type GetWaitingLinePremiumWaitingTimeQueryVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;


export type GetWaitingLinePremiumWaitingTimeQuery = { __typename?: 'Query', getWaitingLine?: { __typename?: 'WaitingLine', id: string, premiumQueueWaitingTime: number } | null };

export type GetWaitingLineNameQueryVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;


export type GetWaitingLineNameQuery = { __typename?: 'Query', getWaitingLine?: { __typename?: 'WaitingLine', id: string, name: { __typename?: 'NullableText', en?: string | null, fr?: string | null, es?: string | null } } | null };


export const SendMagicLoginLinkDocument = gql`
    mutation sendMagicLoginLink($channel: CommunicationChannel!, $id: String!) @api(name: auth) {
  sendMagicLoginLink(channel: $channel, id: $id)
}
    `;
export type SendMagicLoginLinkMutationFn = Apollo.MutationFunction<SendMagicLoginLinkMutation, SendMagicLoginLinkMutationVariables>;

/**
 * __useSendMagicLoginLinkMutation__
 *
 * To run a mutation, you first call `useSendMagicLoginLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendMagicLoginLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendMagicLoginLinkMutation, { data, loading, error }] = useSendMagicLoginLinkMutation({
 *   variables: {
 *      channel: // value for 'channel'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSendMagicLoginLinkMutation(baseOptions?: Apollo.MutationHookOptions<SendMagicLoginLinkMutation, SendMagicLoginLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendMagicLoginLinkMutation, SendMagicLoginLinkMutationVariables>(SendMagicLoginLinkDocument, options);
      }
export type SendMagicLoginLinkMutationHookResult = ReturnType<typeof useSendMagicLoginLinkMutation>;
export type SendMagicLoginLinkMutationResult = Apollo.MutationResult<SendMagicLoginLinkMutation>;
export type SendMagicLoginLinkMutationOptions = Apollo.BaseMutationOptions<SendMagicLoginLinkMutation, SendMagicLoginLinkMutationVariables>;
export const AseLoginDocument = gql`
    mutation aseLogin @api(name: auth) {
  aseLogin
}
    `;
export type AseLoginMutationFn = Apollo.MutationFunction<AseLoginMutation, AseLoginMutationVariables>;

/**
 * __useAseLoginMutation__
 *
 * To run a mutation, you first call `useAseLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAseLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [aseLoginMutation, { data, loading, error }] = useAseLoginMutation({
 *   variables: {
 *   },
 * });
 */
export function useAseLoginMutation(baseOptions?: Apollo.MutationHookOptions<AseLoginMutation, AseLoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AseLoginMutation, AseLoginMutationVariables>(AseLoginDocument, options);
      }
export type AseLoginMutationHookResult = ReturnType<typeof useAseLoginMutation>;
export type AseLoginMutationResult = Apollo.MutationResult<AseLoginMutation>;
export type AseLoginMutationOptions = Apollo.BaseMutationOptions<AseLoginMutation, AseLoginMutationVariables>;
export const LoginMagicTokenDocument = gql`
    mutation loginMagicToken($token: String!) @api(name: auth) {
  loginMagicToken(token: $token) {
    authToken
  }
}
    `;
export type LoginMagicTokenMutationFn = Apollo.MutationFunction<LoginMagicTokenMutation, LoginMagicTokenMutationVariables>;

/**
 * __useLoginMagicTokenMutation__
 *
 * To run a mutation, you first call `useLoginMagicTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMagicTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMagicTokenMutation, { data, loading, error }] = useLoginMagicTokenMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useLoginMagicTokenMutation(baseOptions?: Apollo.MutationHookOptions<LoginMagicTokenMutation, LoginMagicTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMagicTokenMutation, LoginMagicTokenMutationVariables>(LoginMagicTokenDocument, options);
      }
export type LoginMagicTokenMutationHookResult = ReturnType<typeof useLoginMagicTokenMutation>;
export type LoginMagicTokenMutationResult = Apollo.MutationResult<LoginMagicTokenMutation>;
export type LoginMagicTokenMutationOptions = Apollo.BaseMutationOptions<LoginMagicTokenMutation, LoginMagicTokenMutationVariables>;
export const RefreshAuthDocument = gql`
    mutation refreshAuth @api(name: auth) {
  refreshAuth {
    authToken
  }
}
    `;
export type RefreshAuthMutationFn = Apollo.MutationFunction<RefreshAuthMutation, RefreshAuthMutationVariables>;

/**
 * __useRefreshAuthMutation__
 *
 * To run a mutation, you first call `useRefreshAuthMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshAuthMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshAuthMutation, { data, loading, error }] = useRefreshAuthMutation({
 *   variables: {
 *   },
 * });
 */
export function useRefreshAuthMutation(baseOptions?: Apollo.MutationHookOptions<RefreshAuthMutation, RefreshAuthMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RefreshAuthMutation, RefreshAuthMutationVariables>(RefreshAuthDocument, options);
      }
export type RefreshAuthMutationHookResult = ReturnType<typeof useRefreshAuthMutation>;
export type RefreshAuthMutationResult = Apollo.MutationResult<RefreshAuthMutation>;
export type RefreshAuthMutationOptions = Apollo.BaseMutationOptions<RefreshAuthMutation, RefreshAuthMutationVariables>;
export const LogoutDocument = gql`
    mutation logout @api(name: auth) {
  logout
}
    `;
export type LogoutMutationFn = Apollo.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: Apollo.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, options);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const GetEventDocument = gql`
    query getEvent($id: String!) {
  getEvent(id: $id) {
    id
    userId
    name {
      fr
      en
      es
    }
    description {
      fr
      en
      es
    }
    location
    startTime
    endTime
    timezone
    status
    image
    languages
    allowObnlChoice
    theme
  }
}
    `;

/**
 * __useGetEventQuery__
 *
 * To run a query within a React component, call `useGetEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEventQuery(baseOptions: Apollo.QueryHookOptions<GetEventQuery, GetEventQueryVariables> & ({ variables: GetEventQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEventQuery, GetEventQueryVariables>(GetEventDocument, options);
      }
export function useGetEventLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEventQuery, GetEventQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEventQuery, GetEventQueryVariables>(GetEventDocument, options);
        }
export function useGetEventSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetEventQuery, GetEventQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetEventQuery, GetEventQueryVariables>(GetEventDocument, options);
        }
export type GetEventQueryHookResult = ReturnType<typeof useGetEventQuery>;
export type GetEventLazyQueryHookResult = ReturnType<typeof useGetEventLazyQuery>;
export type GetEventSuspenseQueryHookResult = ReturnType<typeof useGetEventSuspenseQuery>;
export type GetEventQueryResult = Apollo.QueryResult<GetEventQuery, GetEventQueryVariables>;
export const GetAllObnlsIdsForEventDocument = gql`
    query getAllObnlsIdsForEvent($eventId: String!, $start: JSON) {
  getAllObnlsIdsForEvent(eventId: $eventId, start: $start) {
    obnls
    lastEvaluated
  }
}
    `;

/**
 * __useGetAllObnlsIdsForEventQuery__
 *
 * To run a query within a React component, call `useGetAllObnlsIdsForEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllObnlsIdsForEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllObnlsIdsForEventQuery({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      start: // value for 'start'
 *   },
 * });
 */
export function useGetAllObnlsIdsForEventQuery(baseOptions: Apollo.QueryHookOptions<GetAllObnlsIdsForEventQuery, GetAllObnlsIdsForEventQueryVariables> & ({ variables: GetAllObnlsIdsForEventQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllObnlsIdsForEventQuery, GetAllObnlsIdsForEventQueryVariables>(GetAllObnlsIdsForEventDocument, options);
      }
export function useGetAllObnlsIdsForEventLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllObnlsIdsForEventQuery, GetAllObnlsIdsForEventQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllObnlsIdsForEventQuery, GetAllObnlsIdsForEventQueryVariables>(GetAllObnlsIdsForEventDocument, options);
        }
export function useGetAllObnlsIdsForEventSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetAllObnlsIdsForEventQuery, GetAllObnlsIdsForEventQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAllObnlsIdsForEventQuery, GetAllObnlsIdsForEventQueryVariables>(GetAllObnlsIdsForEventDocument, options);
        }
export type GetAllObnlsIdsForEventQueryHookResult = ReturnType<typeof useGetAllObnlsIdsForEventQuery>;
export type GetAllObnlsIdsForEventLazyQueryHookResult = ReturnType<typeof useGetAllObnlsIdsForEventLazyQuery>;
export type GetAllObnlsIdsForEventSuspenseQueryHookResult = ReturnType<typeof useGetAllObnlsIdsForEventSuspenseQuery>;
export type GetAllObnlsIdsForEventQueryResult = Apollo.QueryResult<GetAllObnlsIdsForEventQuery, GetAllObnlsIdsForEventQueryVariables>;
export const GetObnlDocument = gql`
    query getObnl($id: String!) {
  getObnl(id: $id) {
    id
    name
    cause
    description {
      fr
      en
      es
    }
    logo
  }
}
    `;

/**
 * __useGetObnlQuery__
 *
 * To run a query within a React component, call `useGetObnlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetObnlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetObnlQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetObnlQuery(baseOptions: Apollo.QueryHookOptions<GetObnlQuery, GetObnlQueryVariables> & ({ variables: GetObnlQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetObnlQuery, GetObnlQueryVariables>(GetObnlDocument, options);
      }
export function useGetObnlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetObnlQuery, GetObnlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetObnlQuery, GetObnlQueryVariables>(GetObnlDocument, options);
        }
export function useGetObnlSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetObnlQuery, GetObnlQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetObnlQuery, GetObnlQueryVariables>(GetObnlDocument, options);
        }
export type GetObnlQueryHookResult = ReturnType<typeof useGetObnlQuery>;
export type GetObnlLazyQueryHookResult = ReturnType<typeof useGetObnlLazyQuery>;
export type GetObnlSuspenseQueryHookResult = ReturnType<typeof useGetObnlSuspenseQuery>;
export type GetObnlQueryResult = Apollo.QueryResult<GetObnlQuery, GetObnlQueryVariables>;
export const SendSupportMessageDocument = gql`
    mutation sendSupportMessage($fields: SupportInput!) {
  sendSupportMessage(fields: $fields)
}
    `;
export type SendSupportMessageMutationFn = Apollo.MutationFunction<SendSupportMessageMutation, SendSupportMessageMutationVariables>;

/**
 * __useSendSupportMessageMutation__
 *
 * To run a mutation, you first call `useSendSupportMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendSupportMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendSupportMessageMutation, { data, loading, error }] = useSendSupportMessageMutation({
 *   variables: {
 *      fields: // value for 'fields'
 *   },
 * });
 */
export function useSendSupportMessageMutation(baseOptions?: Apollo.MutationHookOptions<SendSupportMessageMutation, SendSupportMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendSupportMessageMutation, SendSupportMessageMutationVariables>(SendSupportMessageDocument, options);
      }
export type SendSupportMessageMutationHookResult = ReturnType<typeof useSendSupportMessageMutation>;
export type SendSupportMessageMutationResult = Apollo.MutationResult<SendSupportMessageMutation>;
export type SendSupportMessageMutationOptions = Apollo.BaseMutationOptions<SendSupportMessageMutation, SendSupportMessageMutationVariables>;
export const ReserveTicketsDocument = gql`
    mutation reserveTickets($fields: TicketsReservationInput!) {
  reserveTickets(fields: $fields) {
    timer
    sessionToken
  }
}
    `;
export type ReserveTicketsMutationFn = Apollo.MutationFunction<ReserveTicketsMutation, ReserveTicketsMutationVariables>;

/**
 * __useReserveTicketsMutation__
 *
 * To run a mutation, you first call `useReserveTicketsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReserveTicketsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reserveTicketsMutation, { data, loading, error }] = useReserveTicketsMutation({
 *   variables: {
 *      fields: // value for 'fields'
 *   },
 * });
 */
export function useReserveTicketsMutation(baseOptions?: Apollo.MutationHookOptions<ReserveTicketsMutation, ReserveTicketsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReserveTicketsMutation, ReserveTicketsMutationVariables>(ReserveTicketsDocument, options);
      }
export type ReserveTicketsMutationHookResult = ReturnType<typeof useReserveTicketsMutation>;
export type ReserveTicketsMutationResult = Apollo.MutationResult<ReserveTicketsMutation>;
export type ReserveTicketsMutationOptions = Apollo.BaseMutationOptions<ReserveTicketsMutation, ReserveTicketsMutationVariables>;
export const CancelTicketsDocument = gql`
    mutation cancelTickets($sessionToken: String!) {
  cancelTickets(sessionToken: $sessionToken)
}
    `;
export type CancelTicketsMutationFn = Apollo.MutationFunction<CancelTicketsMutation, CancelTicketsMutationVariables>;

/**
 * __useCancelTicketsMutation__
 *
 * To run a mutation, you first call `useCancelTicketsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelTicketsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelTicketsMutation, { data, loading, error }] = useCancelTicketsMutation({
 *   variables: {
 *      sessionToken: // value for 'sessionToken'
 *   },
 * });
 */
export function useCancelTicketsMutation(baseOptions?: Apollo.MutationHookOptions<CancelTicketsMutation, CancelTicketsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelTicketsMutation, CancelTicketsMutationVariables>(CancelTicketsDocument, options);
      }
export type CancelTicketsMutationHookResult = ReturnType<typeof useCancelTicketsMutation>;
export type CancelTicketsMutationResult = Apollo.MutationResult<CancelTicketsMutation>;
export type CancelTicketsMutationOptions = Apollo.BaseMutationOptions<CancelTicketsMutation, CancelTicketsMutationVariables>;
export const GeneratePassDocument = gql`
    mutation generatePass($device: Device!, $ticket: TicketInput!, $transactionId: String!) {
  generatePass(device: $device, ticket: $ticket, transactionId: $transactionId)
}
    `;
export type GeneratePassMutationFn = Apollo.MutationFunction<GeneratePassMutation, GeneratePassMutationVariables>;

/**
 * __useGeneratePassMutation__
 *
 * To run a mutation, you first call `useGeneratePassMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGeneratePassMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generatePassMutation, { data, loading, error }] = useGeneratePassMutation({
 *   variables: {
 *      device: // value for 'device'
 *      ticket: // value for 'ticket'
 *      transactionId: // value for 'transactionId'
 *   },
 * });
 */
export function useGeneratePassMutation(baseOptions?: Apollo.MutationHookOptions<GeneratePassMutation, GeneratePassMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GeneratePassMutation, GeneratePassMutationVariables>(GeneratePassDocument, options);
      }
export type GeneratePassMutationHookResult = ReturnType<typeof useGeneratePassMutation>;
export type GeneratePassMutationResult = Apollo.MutationResult<GeneratePassMutation>;
export type GeneratePassMutationOptions = Apollo.BaseMutationOptions<GeneratePassMutation, GeneratePassMutationVariables>;
export const BuyTicketsDocument = gql`
    mutation buyTickets($sessionToken: String!, $contactInfo: SessionContactInfo, $transactionId: UUID, $obnlId: String!) {
  buyTickets(
    sessionToken: $sessionToken
    contactInfo: $contactInfo
    transactionId: $transactionId
    obnlId: $obnlId
  ) {
    clientSecret
    returnUrl
  }
}
    `;
export type BuyTicketsMutationFn = Apollo.MutationFunction<BuyTicketsMutation, BuyTicketsMutationVariables>;

/**
 * __useBuyTicketsMutation__
 *
 * To run a mutation, you first call `useBuyTicketsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBuyTicketsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [buyTicketsMutation, { data, loading, error }] = useBuyTicketsMutation({
 *   variables: {
 *      sessionToken: // value for 'sessionToken'
 *      contactInfo: // value for 'contactInfo'
 *      transactionId: // value for 'transactionId'
 *      obnlId: // value for 'obnlId'
 *   },
 * });
 */
export function useBuyTicketsMutation(baseOptions?: Apollo.MutationHookOptions<BuyTicketsMutation, BuyTicketsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BuyTicketsMutation, BuyTicketsMutationVariables>(BuyTicketsDocument, options);
      }
export type BuyTicketsMutationHookResult = ReturnType<typeof useBuyTicketsMutation>;
export type BuyTicketsMutationResult = Apollo.MutationResult<BuyTicketsMutation>;
export type BuyTicketsMutationOptions = Apollo.BaseMutationOptions<BuyTicketsMutation, BuyTicketsMutationVariables>;
export const SendTicketsDocument = gql`
    mutation sendTickets($transactionId: String!) {
  sendTickets(transactionId: $transactionId)
}
    `;
export type SendTicketsMutationFn = Apollo.MutationFunction<SendTicketsMutation, SendTicketsMutationVariables>;

/**
 * __useSendTicketsMutation__
 *
 * To run a mutation, you first call `useSendTicketsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendTicketsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendTicketsMutation, { data, loading, error }] = useSendTicketsMutation({
 *   variables: {
 *      transactionId: // value for 'transactionId'
 *   },
 * });
 */
export function useSendTicketsMutation(baseOptions?: Apollo.MutationHookOptions<SendTicketsMutation, SendTicketsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendTicketsMutation, SendTicketsMutationVariables>(SendTicketsDocument, options);
      }
export type SendTicketsMutationHookResult = ReturnType<typeof useSendTicketsMutation>;
export type SendTicketsMutationResult = Apollo.MutationResult<SendTicketsMutation>;
export type SendTicketsMutationOptions = Apollo.BaseMutationOptions<SendTicketsMutation, SendTicketsMutationVariables>;
export const GetTicketFromTransactionDocument = gql`
    query getTicketFromTransaction($transactionId: String!) {
  getTicketFromTransaction(transactionId: $transactionId) {
    tickets {
      id
      eventId
      waitingLineId
      status
      expiresAt
      transactionId
      owner
      contactInfo {
        type
        value
      }
      displayId
      obnlId
      queueType
      timeSlot {
        startTime
        endTime
      }
    }
  }
}
    `;

/**
 * __useGetTicketFromTransactionQuery__
 *
 * To run a query within a React component, call `useGetTicketFromTransactionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTicketFromTransactionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTicketFromTransactionQuery({
 *   variables: {
 *      transactionId: // value for 'transactionId'
 *   },
 * });
 */
export function useGetTicketFromTransactionQuery(baseOptions: Apollo.QueryHookOptions<GetTicketFromTransactionQuery, GetTicketFromTransactionQueryVariables> & ({ variables: GetTicketFromTransactionQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTicketFromTransactionQuery, GetTicketFromTransactionQueryVariables>(GetTicketFromTransactionDocument, options);
      }
export function useGetTicketFromTransactionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTicketFromTransactionQuery, GetTicketFromTransactionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTicketFromTransactionQuery, GetTicketFromTransactionQueryVariables>(GetTicketFromTransactionDocument, options);
        }
export function useGetTicketFromTransactionSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetTicketFromTransactionQuery, GetTicketFromTransactionQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetTicketFromTransactionQuery, GetTicketFromTransactionQueryVariables>(GetTicketFromTransactionDocument, options);
        }
export type GetTicketFromTransactionQueryHookResult = ReturnType<typeof useGetTicketFromTransactionQuery>;
export type GetTicketFromTransactionLazyQueryHookResult = ReturnType<typeof useGetTicketFromTransactionLazyQuery>;
export type GetTicketFromTransactionSuspenseQueryHookResult = ReturnType<typeof useGetTicketFromTransactionSuspenseQuery>;
export type GetTicketFromTransactionQueryResult = Apollo.QueryResult<GetTicketFromTransactionQuery, GetTicketFromTransactionQueryVariables>;
export const GetTicketWaitingTimeDocument = gql`
    query getTicketWaitingTime($waitingLineId: UUID!, $ticketId: UUID!) {
  getTicketWaitTime(waitingLineId: $waitingLineId, ticketId: $ticketId)
}
    `;

/**
 * __useGetTicketWaitingTimeQuery__
 *
 * To run a query within a React component, call `useGetTicketWaitingTimeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTicketWaitingTimeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTicketWaitingTimeQuery({
 *   variables: {
 *      waitingLineId: // value for 'waitingLineId'
 *      ticketId: // value for 'ticketId'
 *   },
 * });
 */
export function useGetTicketWaitingTimeQuery(baseOptions: Apollo.QueryHookOptions<GetTicketWaitingTimeQuery, GetTicketWaitingTimeQueryVariables> & ({ variables: GetTicketWaitingTimeQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTicketWaitingTimeQuery, GetTicketWaitingTimeQueryVariables>(GetTicketWaitingTimeDocument, options);
      }
export function useGetTicketWaitingTimeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTicketWaitingTimeQuery, GetTicketWaitingTimeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTicketWaitingTimeQuery, GetTicketWaitingTimeQueryVariables>(GetTicketWaitingTimeDocument, options);
        }
export function useGetTicketWaitingTimeSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetTicketWaitingTimeQuery, GetTicketWaitingTimeQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetTicketWaitingTimeQuery, GetTicketWaitingTimeQueryVariables>(GetTicketWaitingTimeDocument, options);
        }
export type GetTicketWaitingTimeQueryHookResult = ReturnType<typeof useGetTicketWaitingTimeQuery>;
export type GetTicketWaitingTimeLazyQueryHookResult = ReturnType<typeof useGetTicketWaitingTimeLazyQuery>;
export type GetTicketWaitingTimeSuspenseQueryHookResult = ReturnType<typeof useGetTicketWaitingTimeSuspenseQuery>;
export type GetTicketWaitingTimeQueryResult = Apollo.QueryResult<GetTicketWaitingTimeQuery, GetTicketWaitingTimeQueryVariables>;
export const ReserveTimeSlotDocument = gql`
    mutation reserveTimeSlot($checkoutSession: String!, $slotId: UUID!) {
  reserveTimeSlot(checkoutSession: $checkoutSession, slotId: $slotId)
}
    `;
export type ReserveTimeSlotMutationFn = Apollo.MutationFunction<ReserveTimeSlotMutation, ReserveTimeSlotMutationVariables>;

/**
 * __useReserveTimeSlotMutation__
 *
 * To run a mutation, you first call `useReserveTimeSlotMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReserveTimeSlotMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reserveTimeSlotMutation, { data, loading, error }] = useReserveTimeSlotMutation({
 *   variables: {
 *      checkoutSession: // value for 'checkoutSession'
 *      slotId: // value for 'slotId'
 *   },
 * });
 */
export function useReserveTimeSlotMutation(baseOptions?: Apollo.MutationHookOptions<ReserveTimeSlotMutation, ReserveTimeSlotMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReserveTimeSlotMutation, ReserveTimeSlotMutationVariables>(ReserveTimeSlotDocument, options);
      }
export type ReserveTimeSlotMutationHookResult = ReturnType<typeof useReserveTimeSlotMutation>;
export type ReserveTimeSlotMutationResult = Apollo.MutationResult<ReserveTimeSlotMutation>;
export type ReserveTimeSlotMutationOptions = Apollo.BaseMutationOptions<ReserveTimeSlotMutation, ReserveTimeSlotMutationVariables>;
export const ReleaseTimeSlotDocument = gql`
    mutation releaseTimeSlot($checkoutSession: String!) {
  releaseTimeSlot(checkoutSession: $checkoutSession)
}
    `;
export type ReleaseTimeSlotMutationFn = Apollo.MutationFunction<ReleaseTimeSlotMutation, ReleaseTimeSlotMutationVariables>;

/**
 * __useReleaseTimeSlotMutation__
 *
 * To run a mutation, you first call `useReleaseTimeSlotMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReleaseTimeSlotMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [releaseTimeSlotMutation, { data, loading, error }] = useReleaseTimeSlotMutation({
 *   variables: {
 *      checkoutSession: // value for 'checkoutSession'
 *   },
 * });
 */
export function useReleaseTimeSlotMutation(baseOptions?: Apollo.MutationHookOptions<ReleaseTimeSlotMutation, ReleaseTimeSlotMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReleaseTimeSlotMutation, ReleaseTimeSlotMutationVariables>(ReleaseTimeSlotDocument, options);
      }
export type ReleaseTimeSlotMutationHookResult = ReturnType<typeof useReleaseTimeSlotMutation>;
export type ReleaseTimeSlotMutationResult = Apollo.MutationResult<ReleaseTimeSlotMutation>;
export type ReleaseTimeSlotMutationOptions = Apollo.BaseMutationOptions<ReleaseTimeSlotMutation, ReleaseTimeSlotMutationVariables>;
export const GetAllWaitingLineTimeSlotsDocument = gql`
    query getAllWaitingLineTimeSlots($waitingLineId: UUID!, $start: JSON) {
  getAllWaitingLineTimeSlots(waitingLineId: $waitingLineId, start: $start) {
    timeSlots {
      id
      startTime
      endTime
      reservedAmount
      maxAmount
    }
    lastEvaluated
  }
}
    `;

/**
 * __useGetAllWaitingLineTimeSlotsQuery__
 *
 * To run a query within a React component, call `useGetAllWaitingLineTimeSlotsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllWaitingLineTimeSlotsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllWaitingLineTimeSlotsQuery({
 *   variables: {
 *      waitingLineId: // value for 'waitingLineId'
 *      start: // value for 'start'
 *   },
 * });
 */
export function useGetAllWaitingLineTimeSlotsQuery(baseOptions: Apollo.QueryHookOptions<GetAllWaitingLineTimeSlotsQuery, GetAllWaitingLineTimeSlotsQueryVariables> & ({ variables: GetAllWaitingLineTimeSlotsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllWaitingLineTimeSlotsQuery, GetAllWaitingLineTimeSlotsQueryVariables>(GetAllWaitingLineTimeSlotsDocument, options);
      }
export function useGetAllWaitingLineTimeSlotsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllWaitingLineTimeSlotsQuery, GetAllWaitingLineTimeSlotsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllWaitingLineTimeSlotsQuery, GetAllWaitingLineTimeSlotsQueryVariables>(GetAllWaitingLineTimeSlotsDocument, options);
        }
export function useGetAllWaitingLineTimeSlotsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetAllWaitingLineTimeSlotsQuery, GetAllWaitingLineTimeSlotsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAllWaitingLineTimeSlotsQuery, GetAllWaitingLineTimeSlotsQueryVariables>(GetAllWaitingLineTimeSlotsDocument, options);
        }
export type GetAllWaitingLineTimeSlotsQueryHookResult = ReturnType<typeof useGetAllWaitingLineTimeSlotsQuery>;
export type GetAllWaitingLineTimeSlotsLazyQueryHookResult = ReturnType<typeof useGetAllWaitingLineTimeSlotsLazyQuery>;
export type GetAllWaitingLineTimeSlotsSuspenseQueryHookResult = ReturnType<typeof useGetAllWaitingLineTimeSlotsSuspenseQuery>;
export type GetAllWaitingLineTimeSlotsQueryResult = Apollo.QueryResult<GetAllWaitingLineTimeSlotsQuery, GetAllWaitingLineTimeSlotsQueryVariables>;
export const ClaimFreeTicketsDocument = gql`
    mutation claimFreeTickets($fields: ClaimFreeTicketsInput!) {
  claimFreeTickets(fields: $fields)
}
    `;
export type ClaimFreeTicketsMutationFn = Apollo.MutationFunction<ClaimFreeTicketsMutation, ClaimFreeTicketsMutationVariables>;

/**
 * __useClaimFreeTicketsMutation__
 *
 * To run a mutation, you first call `useClaimFreeTicketsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClaimFreeTicketsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [claimFreeTicketsMutation, { data, loading, error }] = useClaimFreeTicketsMutation({
 *   variables: {
 *      fields: // value for 'fields'
 *   },
 * });
 */
export function useClaimFreeTicketsMutation(baseOptions?: Apollo.MutationHookOptions<ClaimFreeTicketsMutation, ClaimFreeTicketsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ClaimFreeTicketsMutation, ClaimFreeTicketsMutationVariables>(ClaimFreeTicketsDocument, options);
      }
export type ClaimFreeTicketsMutationHookResult = ReturnType<typeof useClaimFreeTicketsMutation>;
export type ClaimFreeTicketsMutationResult = Apollo.MutationResult<ClaimFreeTicketsMutation>;
export type ClaimFreeTicketsMutationOptions = Apollo.BaseMutationOptions<ClaimFreeTicketsMutation, ClaimFreeTicketsMutationVariables>;
export const GetAllEventWaitingLinesDocument = gql`
    query getAllEventWaitingLines($eventId: UUID!, $start: JSON) {
  getAllEventWaitingLines(eventId: $eventId, start: $start) {
    waitingLines {
      id
      name {
        fr
        en
        es
      }
      description {
        fr
        en
        es
      }
      productDescription {
        fr
        en
        es
      }
      status
      message {
        fr
        en
        es
      }
      waitingLineType
      isFreemium
      price
      currency
      maxGroupSize
      quantity
      limitedQuantity
      usedTickets
      activatedTickets
      hasTimeSlots
      timeSlotStart
    }
    lastEvaluated
  }
}
    `;

/**
 * __useGetAllEventWaitingLinesQuery__
 *
 * To run a query within a React component, call `useGetAllEventWaitingLinesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllEventWaitingLinesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllEventWaitingLinesQuery({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      start: // value for 'start'
 *   },
 * });
 */
export function useGetAllEventWaitingLinesQuery(baseOptions: Apollo.QueryHookOptions<GetAllEventWaitingLinesQuery, GetAllEventWaitingLinesQueryVariables> & ({ variables: GetAllEventWaitingLinesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllEventWaitingLinesQuery, GetAllEventWaitingLinesQueryVariables>(GetAllEventWaitingLinesDocument, options);
      }
export function useGetAllEventWaitingLinesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllEventWaitingLinesQuery, GetAllEventWaitingLinesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllEventWaitingLinesQuery, GetAllEventWaitingLinesQueryVariables>(GetAllEventWaitingLinesDocument, options);
        }
export function useGetAllEventWaitingLinesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetAllEventWaitingLinesQuery, GetAllEventWaitingLinesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAllEventWaitingLinesQuery, GetAllEventWaitingLinesQueryVariables>(GetAllEventWaitingLinesDocument, options);
        }
export type GetAllEventWaitingLinesQueryHookResult = ReturnType<typeof useGetAllEventWaitingLinesQuery>;
export type GetAllEventWaitingLinesLazyQueryHookResult = ReturnType<typeof useGetAllEventWaitingLinesLazyQuery>;
export type GetAllEventWaitingLinesSuspenseQueryHookResult = ReturnType<typeof useGetAllEventWaitingLinesSuspenseQuery>;
export type GetAllEventWaitingLinesQueryResult = Apollo.QueryResult<GetAllEventWaitingLinesQuery, GetAllEventWaitingLinesQueryVariables>;
export const GetWaitingLineDocument = gql`
    query getWaitingLine($id: UUID!) {
  getWaitingLine(id: $id) {
    id
    name {
      fr
      en
      es
    }
    description {
      fr
      en
      es
    }
    status
    waitingLineType
    maxGroupSize
    price
    isFreemium
    currency
    quantity
    limitedQuantity
    usedTickets
    activatedTickets
    hasTimeSlots
    timeSlotStart
    productDescription {
      fr
      en
      es
    }
  }
}
    `;

/**
 * __useGetWaitingLineQuery__
 *
 * To run a query within a React component, call `useGetWaitingLineQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWaitingLineQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWaitingLineQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetWaitingLineQuery(baseOptions: Apollo.QueryHookOptions<GetWaitingLineQuery, GetWaitingLineQueryVariables> & ({ variables: GetWaitingLineQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWaitingLineQuery, GetWaitingLineQueryVariables>(GetWaitingLineDocument, options);
      }
export function useGetWaitingLineLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWaitingLineQuery, GetWaitingLineQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWaitingLineQuery, GetWaitingLineQueryVariables>(GetWaitingLineDocument, options);
        }
export function useGetWaitingLineSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetWaitingLineQuery, GetWaitingLineQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetWaitingLineQuery, GetWaitingLineQueryVariables>(GetWaitingLineDocument, options);
        }
export type GetWaitingLineQueryHookResult = ReturnType<typeof useGetWaitingLineQuery>;
export type GetWaitingLineLazyQueryHookResult = ReturnType<typeof useGetWaitingLineLazyQuery>;
export type GetWaitingLineSuspenseQueryHookResult = ReturnType<typeof useGetWaitingLineSuspenseQuery>;
export type GetWaitingLineQueryResult = Apollo.QueryResult<GetWaitingLineQuery, GetWaitingLineQueryVariables>;
export const GetWaitingLinePremiumWaitingTimeDocument = gql`
    query getWaitingLinePremiumWaitingTime($id: UUID!) {
  getWaitingLine(id: $id) {
    id
    premiumQueueWaitingTime
  }
}
    `;

/**
 * __useGetWaitingLinePremiumWaitingTimeQuery__
 *
 * To run a query within a React component, call `useGetWaitingLinePremiumWaitingTimeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWaitingLinePremiumWaitingTimeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWaitingLinePremiumWaitingTimeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetWaitingLinePremiumWaitingTimeQuery(baseOptions: Apollo.QueryHookOptions<GetWaitingLinePremiumWaitingTimeQuery, GetWaitingLinePremiumWaitingTimeQueryVariables> & ({ variables: GetWaitingLinePremiumWaitingTimeQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWaitingLinePremiumWaitingTimeQuery, GetWaitingLinePremiumWaitingTimeQueryVariables>(GetWaitingLinePremiumWaitingTimeDocument, options);
      }
export function useGetWaitingLinePremiumWaitingTimeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWaitingLinePremiumWaitingTimeQuery, GetWaitingLinePremiumWaitingTimeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWaitingLinePremiumWaitingTimeQuery, GetWaitingLinePremiumWaitingTimeQueryVariables>(GetWaitingLinePremiumWaitingTimeDocument, options);
        }
export function useGetWaitingLinePremiumWaitingTimeSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetWaitingLinePremiumWaitingTimeQuery, GetWaitingLinePremiumWaitingTimeQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetWaitingLinePremiumWaitingTimeQuery, GetWaitingLinePremiumWaitingTimeQueryVariables>(GetWaitingLinePremiumWaitingTimeDocument, options);
        }
export type GetWaitingLinePremiumWaitingTimeQueryHookResult = ReturnType<typeof useGetWaitingLinePremiumWaitingTimeQuery>;
export type GetWaitingLinePremiumWaitingTimeLazyQueryHookResult = ReturnType<typeof useGetWaitingLinePremiumWaitingTimeLazyQuery>;
export type GetWaitingLinePremiumWaitingTimeSuspenseQueryHookResult = ReturnType<typeof useGetWaitingLinePremiumWaitingTimeSuspenseQuery>;
export type GetWaitingLinePremiumWaitingTimeQueryResult = Apollo.QueryResult<GetWaitingLinePremiumWaitingTimeQuery, GetWaitingLinePremiumWaitingTimeQueryVariables>;
export const GetWaitingLineNameDocument = gql`
    query getWaitingLineName($id: UUID!) {
  getWaitingLine(id: $id) {
    id
    name {
      en
      fr
      es
    }
  }
}
    `;

/**
 * __useGetWaitingLineNameQuery__
 *
 * To run a query within a React component, call `useGetWaitingLineNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWaitingLineNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWaitingLineNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetWaitingLineNameQuery(baseOptions: Apollo.QueryHookOptions<GetWaitingLineNameQuery, GetWaitingLineNameQueryVariables> & ({ variables: GetWaitingLineNameQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWaitingLineNameQuery, GetWaitingLineNameQueryVariables>(GetWaitingLineNameDocument, options);
      }
export function useGetWaitingLineNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWaitingLineNameQuery, GetWaitingLineNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWaitingLineNameQuery, GetWaitingLineNameQueryVariables>(GetWaitingLineNameDocument, options);
        }
export function useGetWaitingLineNameSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetWaitingLineNameQuery, GetWaitingLineNameQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetWaitingLineNameQuery, GetWaitingLineNameQueryVariables>(GetWaitingLineNameDocument, options);
        }
export type GetWaitingLineNameQueryHookResult = ReturnType<typeof useGetWaitingLineNameQuery>;
export type GetWaitingLineNameLazyQueryHookResult = ReturnType<typeof useGetWaitingLineNameLazyQuery>;
export type GetWaitingLineNameSuspenseQueryHookResult = ReturnType<typeof useGetWaitingLineNameSuspenseQuery>;
export type GetWaitingLineNameQueryResult = Apollo.QueryResult<GetWaitingLineNameQuery, GetWaitingLineNameQueryVariables>;