import { createTheme, PaletteOptions } from '@mui/material';

import defaultMuiThemeOptions from '../defaultMuiThemeOptions';
import { AppTheme } from '../types/AppTheme';

const palette: PaletteOptions = {
  mode: 'light',
  primary: {
    main: '#303036',
    contrastText: '#fff',
  },
  secondary: {
    main: '#303036',
    contrastText: '#fff',
  },
  background: {
    default: '#f3e8ee',
    paper: '#fff',
  },
  text: {
    primary: '#000',
  },
  error: {
    main: '#e85050',
    contrastText: '#ffffff',
  },
  warning: {
    main: '#f67044',
    contrastText: '#ffffff',
  },
  success: {
    main: '#72d59c',
    contrastText: '#ffffff',
  },
  custom: {
    background: {
      contrastText: '#000',
      dark: '#a6b6c7',
    },
    upgradeBlock: {
      background: '#00000044',
      contrastText: '#fff',
      arrow: '#00000066',
    },
    lightGray: '#eeeeee',
    gray: '#4D4F53',
    secondaryAccent: {
      altContrastText: '#fff',
      main: '#00000033',
      contrastText: '#000',
    },
    input: {
      background: '#fff',
      color: '#000',
    },
  },
};

const theme: AppTheme = {
  name: 'Lavender Blush',
  theme: createTheme({
    ...defaultMuiThemeOptions,
    palette,
  }),
};

export default theme;
