import useFeedback from 'components/Feedback/useFeedback';
import CenteredSpinner from 'components/Loading/CenteredSpinner';
import QRHolder from 'components/QRHolder/QRHolder';
import TimeSlotWaitInfos from 'components/TimeSlotWaitInfos';
import WhatsNext from 'components/WhatsNext/WhatsNext';
import {
  TicketStatus,
  useGetEventQuery,
  useGetObnlQuery,
  useGetTicketFromTransactionSuspenseQuery,
  useGetWaitingLineQuery,
  useSendTicketsMutation,
} from 'lib/graphql/graphql';
import LanguageContext, { Languages } from 'lib/providers/Language/LanguageContext';
import { storeTransaction } from 'lib/stores/transactions';
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { FormattedMessage } from 'react-intl';
import { CenteredContainer } from 'styles/styles';
import { useLocation } from 'wouter';

import {
  Congratulations,
  CongratulationsName,
  ResendButton,
  SmallText,
} from './styles';

function ConfirmationPass() {
  const { language } = useContext(LanguageContext);

  const [sendTickets] = useSendTicketsMutation();
  const feedback = useFeedback();

  const transactionId = useMemo(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    return urlSearchParams.get('id');
  }, []);

  const [, setLocation] = useLocation();

  const { data, refetch } = useGetTicketFromTransactionSuspenseQuery({
    variables: { transactionId: transactionId! },
    skip: !transactionId,
    fetchPolicy: 'network-only',
  });

  const tickets = useMemo(() => data?.getTicketFromTransaction.tickets || [], [data]);
  const owner = tickets[0]?.owner || undefined;
  const communicationAddress = tickets[0]?.contactInfo?.value;
  const obnlId = tickets[0]?.obnlId;

  useEffect(() => {
    if (tickets.length > 0 && transactionId) {
      storeTransaction({ isFreemium: false, transactionId });
    }
  }, [tickets.length, transactionId]);

  const shouldRefetch = tickets.length > 0 && tickets[0].status !== TicketStatus.Activated;
  useEffect(() => {
    if (shouldRefetch) {
      refetch();
    }
  }, [refetch, shouldRefetch]);

  const { data: eventData, loading: eventLoading } = useGetEventQuery({
    variables: { id: tickets[0].eventId },
    skip: !tickets[0]?.eventId,
  });
  const event = eventData?.getEvent;

  const { data: waitingLineData, loading: waitingLineLoading } = useGetWaitingLineQuery({
    variables: { id: tickets[0].waitingLineId },
    fetchPolicy: 'network-only',
    skip: !tickets[0]?.waitingLineId,
  });

  const { data: obnlData, loading: obnlLoading } = useGetObnlQuery({
    variables: { id: obnlId! },
    fetchPolicy: 'network-only',
  });

  const waitingLine = waitingLineData?.getWaitingLine;

  const onResendTickets = useCallback(async () => {
    try {
      await sendTickets({
        variables: {
          transactionId: transactionId!,
        },
      });
      feedback({
        anchor: { horizontal: 'right', vertical: 'bottom' },
        content: (<FormattedMessage id="pass_resend_success" />),
        severity: 'success',
      });
    } catch (error) {
      feedback({
        anchor: { horizontal: 'right', vertical: 'bottom' },
        content: (<FormattedMessage id="pass_resend_error" />),
        severity: 'error',
      });
    }
  }, [transactionId, sendTickets, feedback]);

  if (eventLoading || waitingLineLoading || !event
    || !waitingLine || shouldRefetch || obnlLoading) {
    return <CenteredSpinner />;
  }

  // TODO Add feedback when no tickets are found
  if (!transactionId || !tickets.length) setLocation('/');

  const eventLanguage = event.languages[0] as Languages;
  const timeSlot = tickets[0].timeSlot || undefined;
  const waitingLineDescription = waitingLine.description?.[language] ?? waitingLine.description?.[eventLanguage] ?? '';

  return (
    <CenteredContainer>
      <Congratulations>
        <FormattedMessage id="pass_congratulations" />
        {owner !== undefined && (
          <CongratulationsName>{`${owner} `}</CongratulationsName>
        )}
        !
      </Congratulations>
      <SmallText>
        <FormattedMessage id="pass_sent_to" values={{ email: communicationAddress }} />
      </SmallText>
      <ResendButton onClick={onResendTickets}>
        <FormattedMessage id="pass_resend_confirmation" />
      </ResendButton>
      <SmallText>
        {timeSlot !== undefined && (
          <TimeSlotWaitInfos infos={timeSlot} />
        )}
      </SmallText>
      <QRHolder
        event={event}
        waitingLine={waitingLine}
        tickets={tickets}
        transactionId={transactionId}
      />
      <SmallText>
        <FormattedMessage id="cart_summary_movon" values={{ obnl: <b>{obnlData?.getObnl?.name}</b> }} />
      </SmallText>
      {waitingLineDescription && (
        <div>
          <WhatsNext waitingLineDescription={waitingLineDescription} />
        </div>
      )}
    </CenteredContainer>
  );
}

export default ConfirmationPass;
