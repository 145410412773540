import { TimeSlot } from 'lib/graphql/timeSlot/types';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import {
  Container,
  Date,
  DayNumber,
  Dot,
} from './styles';

interface DayItemProps {
  day: TimeSlot[]
  onSelect: () => void
  selected: boolean
  selectedSlot: string
}

function DayItem(props: DayItemProps) {
  const {
    day,
    onSelect,
    selected,
    selectedSlot,
  } = props;

  const { locale } = useIntl();

  const datetime = useMemo(() => DateTime.fromISO(day[0].startTime), [day]);
  const isPast = useMemo(() => datetime.startOf('day').diff(DateTime.now().startOf('day')).milliseconds < 0, [datetime]);
  const available = useMemo(() => (
    !isPast && day.some((d) => d.maxAmount - d.reservedAmount > 0)
  ), [day, isPast]);

  const hasSelection = useMemo(() => day.some((d) => d.id === selectedSlot), [day, selectedSlot]);

  const dayOfWeek = useMemo(() => datetime.setLocale(locale).toFormat('ccccc'), [datetime, locale]);
  const dayOfMonth = useMemo(() => datetime.toFormat('dd'), [datetime]);
  const month = useMemo(() => datetime.setLocale(locale).toFormat('LLL'), [datetime, locale]);

  return (
    <Container>
      <Date
        $selected={selected}
        $hasSelection={hasSelection}
        disabled={!available}
        onClick={onSelect}
      >
        <span>{dayOfWeek}</span>
        <DayNumber>{dayOfMonth}</DayNumber>
        <span>{month}</span>
      </Date>
      <Dot $available={available} />
    </Container>
  );
}

export default DayItem;
