import FRAddToAppleWallet from 'assets/appleWallet/CAFR_Add_to_Apple_Wallet.png';
import ESAddToAppleWallet from 'assets/appleWallet/ES_Add_to_Apple_Wallet.png';
import ENAddToAppleWallet from 'assets/appleWallet/US-UK_Add_to_Apple_Wallet.png';
import ENAddToGoogleWallet from 'assets/googleWallet/enUS_add_to_google_wallet_wallet-button.png';
import ESAddToGoogleWallet from 'assets/googleWallet/esUS_add_to_google_wallet_wallet-button.png';
import FRAddToGoogleWallet from 'assets/googleWallet/frCA_add_to_google_wallet_wallet-button.png';
import QrTemplateLong from 'assets/images/qr-template-long.png';
import { base64StringToBlob } from 'blob-util';
import ErrorPage from 'components/ErrorPage/ErrorPage';
import { DEVICE } from 'lib/detection';
import { GetEventItem } from 'lib/graphql/event/types';
import { Device, useGeneratePassMutation } from 'lib/graphql/graphql';
import { GetTicketsFromTransactionItem } from 'lib/graphql/tickets/types';
import { GetWaitingLineItem } from 'lib/graphql/waitingLines/types';
import LanguageContext, { Languages } from 'lib/providers/Language/LanguageContext';
import { createCanvasFromImage, drawQRCode } from 'lib/qrcode';
import { useContext, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import QRCode from 'react-qr-code';
import { SecondaryLinkButton } from 'styles/styles';

import {
  AddToWalletButton,
  AddToWalletImage,
  QRSection,
  QRSubTitle,
  QRTitle,
} from './styles';

interface QRHolderProps {
  event: NonNullable<GetEventItem>;
  waitingLine: NonNullable<GetWaitingLineItem>;
  tickets: GetTicketsFromTransactionItem[];
  transactionId: string | null;
  displayWaitingLineInfos?: boolean;
}

function QRHolder(props: QRHolderProps) {
  const {
    event,
    waitingLine,
    tickets,
    transactionId,
    displayWaitingLineInfos = true,
  } = props;

  const intl = useIntl();
  const { language } = useContext(LanguageContext);

  const AppleWalletImg = useMemo(() => {
    switch (language) {
      case 'fr':
        return FRAddToAppleWallet;
      case 'en':
        return ENAddToAppleWallet;
      case 'es':
        return ESAddToAppleWallet;
      default:
        return FRAddToAppleWallet;
    }
  }, [language]);

  const GoogleWalletImg = useMemo(() => {
    switch (language) {
      case 'fr':
        return FRAddToGoogleWallet;
      case 'en':
        return ENAddToGoogleWallet;
      case 'es':
        return ESAddToGoogleWallet;
      default:
        return FRAddToGoogleWallet;
    }
  }, [language]);

  const [generatePass, { error, loading }] = useGeneratePassMutation();

  const fetchPass = async () => {
    if (!transactionId) return null;
    if (!tickets || tickets.length === 0) return null;
    if (!tickets[0].eventId || !tickets[0].waitingLineId) return null;
    const generatePassResult = await generatePass({
      variables: {
        device: DEVICE,
        transactionId,
        ticket: {
          eventId: tickets[0].eventId,
          waitingLineId: tickets[0].waitingLineId,
          ids: tickets.map((ticket) => ticket.id),
        },
      },
    });
    if (generatePassResult.data) {
      return generatePassResult.data.generatePass;
    }
    return null;
  };

  const getApplePass = async () => {
    const pass = await fetchPass();
    if (pass) {
      // Create blob from base64 string
      const blob = base64StringToBlob(pass, 'application/vnd.apple.pkpass');

      // Create a new object URL for the blob
      const url = URL.createObjectURL(blob);

      // Create a link and programmatically click it to trigger the download
      const link = document.createElement('a');
      link.href = url;
      link.download = 'pass.pkpass';
      link.click();
    } else console.error('An error occurred while generating the pass.');
  };

  const getGooglePass = async () => {
    const pass = await fetchPass();
    if (pass) window.open(`https://pay.google.com/gp/v/save/${pass}`, '_blank');
    else console.error('An error occurred while generating the pass.');
  };

  const downloadQR = async () => {
    const svg = document.getElementById('qrcode');
    if (!svg) throw new Error('SVG not found');
    const svgData = new XMLSerializer().serializeToString(svg);

    try {
      const canvas = await createCanvasFromImage(QrTemplateLong);
      const quantity = tickets.length;
      await drawQRCode(canvas, svgData, waitingLine, event, language, quantity, intl);

      const pngFile = canvas.toDataURL('image/png');

      const downloadLink = document.createElement('a');
      downloadLink.download = 'qrcode.png';
      downloadLink.href = `${pngFile}`;
      downloadLink.click();
    } catch (e) {
      // TODO: Add feedback error message
      console.error('Failed to generate QR code image: ', e);
    }
  };

  const eventLanguage = event.languages[0] as Languages;

  if (error) return <ErrorPage title={error.message} />;

  return (
    <QRSection>
      {displayWaitingLineInfos && (
        <>
          <QRTitle>{event.name[language] || event.name[eventLanguage]}</QRTitle>
          <QRSubTitle>{waitingLine.name[language] || waitingLine.name[eventLanguage]}</QRSubTitle>
        </>
      )}
      <QRCode
        id="qrcode"
        value={JSON.stringify({
          eventId: tickets[0].eventId,
          waitingLineId: tickets[0].waitingLineId,
          ids: tickets.map((ticket) => ticket.id),
        })}
        size={192}
      />
      {DEVICE === Device.Apple && (
        <AddToWalletButton onClick={getApplePass} disabled={loading}>
          <AddToWalletImage src={AppleWalletImg} alt="Apple Wallet" />
        </AddToWalletButton>
      )}
      {DEVICE === Device.Google && (
        <AddToWalletButton onClick={getGooglePass} disabled={loading}>
          <AddToWalletImage src={GoogleWalletImg} alt="Google Wallet" />
        </AddToWalletButton>
      )}
      <SecondaryLinkButton onClick={downloadQR}>
        <FormattedMessage id="pass_download_pass" />
      </SecondaryLinkButton>
    </QRSection>
  );
}

export default QRHolder;
