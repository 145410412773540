/* cSpell:language fr, en */

import policiesFr from './LegalAgreements/policiesFr';
import termsFr from './LegalAgreements/TermsFr';

const messages: I18NMessages = {
  about_movon: 'À propos de MovOn.VIP',

  alert_something_wrong: 'Une erreur s\'est produite!',
  alert_waiting_line_error: 'Une erreur est survenue, veuillez ré-essayer plus tard.',
  alert_waiting_line_not_active: 'La file d\'attente est inactive. Veuillez essayer plus tard.',
  alert_waiting_line_not_enough_tickets: 'Il n\'y a pas assez de laissez-passer disponibles. Merci de votre compréhension.',
  alert_waiting_line_reservation_expired: 'Le temps réservé pour acheter vos laissez-passer a expiré. Veuillez réessayer.',
  alert_return: 'Retour aux laissez-passer',

  cancel_order_title: 'Êtes-vous sûr de vouloir annuler votre commande?',
  cancel_order_body: 'Quitter maintenant annulera votre commande et vous fera perdre votre position dans la file d\'attente. Revenir en arrière?',

  cart_choose_for_me: 'Choisir pour moi',
  cart_choose: 'Je veux choisir',
  cart_countdown: 'Temps restant: {time}',
  cart_email: 'Courriel',
  cart_reservation: 'Réservation',
  cart_reservation_empty: 'Aucune plage disponible',
  cart_reservation_slot_full: 'Complet',
  cart_reservation_slot_remaining: '{remaining} restant{remaining, plural, one {} other {s}}',
  cart_sms: 'SMS',
  cart_summary: 'Résumé',
  cart_summary_movon: 'MovOn.VIP s\'engage à verser 33% des revenus à {obnl}',
  cart_impact: 'Impact',
  cart_impact_description: 'Pour chaque laissez-passer MovOn.VIP vendu,',
  cart_impact_follow: '33% seront versés à une cause charitable',
  cart_communication: 'Contact',
  cart_communication_description: 'Choisissez votre méthode de communication préférée pour recevoir des mises à jour sur le statut de vos laissez-passer.',
  cart_communication_opt_in_notifications: 'Recevoir des notifications de l\'organisateur',
  cart_payment: 'Paiement',
  cart_remove: 'Retirer',
  cart_subtotal: 'Sous-total',
  cart_tax: 'Taxes',

  event_starts: 'Valide du',
  event_ends: 'Jusqu\'au',
  event_location: 'Lieu de l\'événement',
  event_no_waiting_line_available: 'Aucune file d\'attente n\'a été créée pour cet événement.',
  event_timezone: 'Fuseau horaire',

  get_vip_access: 'Obtenez un accès VIP',

  general_back: 'Retour',
  general_cancel: 'Annuler',
  general_close: 'Fermer',
  generic_form_error_required: 'Ce champs est requis',
  general_next: 'Suivant',
  general_no: 'Non',
  general_select: 'Choisir',
  general_yes: 'Oui',

  movon_title: 'MovOn.VIP',

  obnl_cities: 'Villes et communautés durables',
  obnl_climate: 'Mesures contre les changements climatiques',
  obnl_consumption: 'Consommation et production responsables',
  obnl_education: 'Éducation de qualité',
  obnl_energy: 'Énergie abordable et propre',
  obnl_gender: 'Égalité entre les sexes',
  obnl_health: 'Bonne santé et bien-être',
  obnl_hunger: 'Faim zéro',
  obnl_industry: 'Industrie, innovation et infrastructure',
  obnl_inequalities: 'Inégalités réduites',
  obnl_life_land: 'Vie terrestre',
  obnl_partnerships: 'Partenariats pour la réalisation des objectifs',
  obnl_peace: 'Paix, justice et institutions efficaces',
  obnl_poverty: 'Pas de pauvreté',
  obnl_water: 'Eau propre et assainissement',
  obnl_water_life: 'Vie aquatique',
  obnl_work: 'Travail décent et croissance économique',

  onboarding_cause: 'Soutenez une cause qui vous tient à coeur.',
  onboarding_obtain: 'Obtenez un accès VIP',

  pass_congratulations: 'Félicitations ',
  pass_download_pass: 'ou téléchargez votre laissez-passer',
  pass_owner_gold: 'Laissez-passer VIP Gold de {name}',
  pass_owner_silver: 'Laissez-passer VIP Silver de {name}',
  pass_owner_silver_no_name: 'Votre laissez-passer VIP Silver',
  pass_owner_gold_no_name: 'Votre laissez-passer VIP Gold',
  pass_gold_vip: 'Vous êtes désormais VIP Gold',
  pass_silver_vip: 'Vous êtes désormais VIP Silver',
  pass_help: 'Besoin d\'aide?',
  pass_line_status: 'Status de la file d\'attente',
  pass_resend_confirmation: 'Renvoyer la confirmation',
  pass_resend_error: 'Une erreur s\'est produite lors de l\'envoi de votre confirmation. Veuillez réessayer.',
  pass_resend_success: 'Votre confirmation a été renvoyée avec succès.',
  pass_sent_to: 'Votre laissez-passer a été envoyé vers {email}',
  pass_show_pass: 'Montrez votre laissez-passer à un caissier pour sauter la file d\'attente',
  pass_what_next: 'Prochaine étape?',

  pdq_avoid_waiting_line: 'Évitez la file d\'attente!',
  pdq_in_a_hurry: 'Très pressé?',

  passes: '{quantity} laissez-passer',

  placeholder_email_address: 'Adresse courriel',
  placeholder_sms_number: 'Numéro de téléphone',

  payment_information: 'Informations de paiement',

  reload: 'Rafraîchir',

  support_cause: 'Soutenez une cause qui vous tient à coeur.',

  support_title: 'Soutien',
  support_name: 'Nom (optionnel)',
  support_email: 'Adresse courriel',
  support_subject: 'Sujet',
  support_message: 'Message',
  support_send: 'Envoyer',
  support_sent_error: 'Une erreur s\'est produite lors de l\'envoi de votre message. Veuillez réessayer ou envoyer un courriel à help@movon.vip.',
  support_sent_success: 'Votre message a été envoyé avec succès. Nous vous répondrons dans les plus brefs délais.',

  tickets_enter: 'Entrer',
  tickets_checkout: 'Caisse',
  tickets_gold_title: 'Sautez la file d\'attente',
  tickets_group_size: 'Limite par transaction',
  tickets_group_size_value: '{limit} laissez-passer',
  tickets_quantity_available: '{quantity} disponible{quantity, plural, one {} other {s}}',
  tickets_quantity: 'Quantité',
  tickets_quantity_sold_out: 'Tous les laissez-passer sont vendus',
  tickets_reserve: 'Réserver',
  tickets_silver_title: 'Sauvez votre place',

  use_camera_app_qr: 'Utilisez la <tag>caméra</tag> de votre appareil pour scanner le code QR.',

  waiting_line_called: 'C\'est à votre tour!',
  waiting_line_claim_free_invalid_email: 'Votre adresse courriel doit au moins contenir un @.',
  waiting_line_register: 'Enregistrez-vous!',
  waiting_line_register_use_email: 'Utiliser une adresse courriel',
  waiting_line_register_use_sms: 'Utiliser un numéro de téléphone',
  waiting_line_status_info_title: 'Informations sur le statut',
  waiting_line_waiting_estimated_disclaimer: '* temps estimé',
  waiting_line_waiting_estimated_wait: 'Temps d\'attente estimé: {minutes} min',
  waiting_line_waiting_price: 'pour seulement <Price>{price}</Price>',
  waiting_line_waiting_saved_time: 'Sauvez {minutes} minutes*',
  waiting_line_waiting_ticket_number: 'Votre numéro de laissez-passer',
  waiting_line_waiting_time_slot: 'Ce sera votre tour le {date} entre {start} et {end}',
  waiting_line_waiting_upgrade: 'Passer à VIP',
  waiting_line_waiting_welcome: 'Bienvenue,',

  ...policiesFr,
  ...termsFr,
};

export default messages;
