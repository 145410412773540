import goldIcon from 'assets/images/gold-ticket-v2.png';
import silverIcon from 'assets/images/Silver-ticket-v2.png';
import NumberInput from 'components/NumberInput/NumberInput';
import { currencies } from 'lib/currencies';
import getMaxAmountLeft from 'lib/getMaxAmountLeft';
import {
  GetAllEventWaitingLinesQuery,
  SessionType,
  WaitingLineStatus,
  WaitingLineType,
} from 'lib/graphql/graphql';
import LanguageContext, { Languages } from 'lib/providers/Language/LanguageContext';
import { DateTime } from 'luxon';
import QuantityInfos from 'modules/Cart/components/QuantityInfos/QuantityInfos';
import { EventContext } from 'providers/EventProvider/EventContext';
import { TicketingContext } from 'providers/TicketingProvider/TicketingContext';
import { useContext, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { OutlinedPrimaryButton, PrimaryButton } from 'styles/styles';
import { useLocation } from 'wouter';

import InformationModal from './components/InformationModal/InformationModal';
import StatusBar from './components/StatusBar/StatusBar';
import {
  ButtonsContainer,
  Price,
  Spacer,
  TickerHeader,
  TicketActions,
  TicketContainer,
  TicketContent,
  TicketContentText,
  TicketDescription,
  TicketIcon,
  TicketTitle,
} from './styles';

type WaitingLine = GetAllEventWaitingLinesQuery['getAllEventWaitingLines']['waitingLines'][number];

interface TicketProps {
  waitingLine: WaitingLine
}

function Ticket(props: TicketProps) {
  const { waitingLine } = props;
  const {
    id,
    isFreemium,
    limitedQuantity,
    quantity,
    status,
    message,
    price,
    currency,
    maxGroupSize,
    waitingLineType,
    name,
    usedTickets = 0,
    productDescription,
    hasTimeSlots,
  } = waitingLine;

  const [, setLocation] = useLocation();

  const { checkout } = useContext(TicketingContext);

  const event = useContext(EventContext);
  const { language } = useContext(LanguageContext);

  const [checkoutQuantity, setCheckoutQuantity] = useState<number>(1);
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const eventStarted = useMemo(() => (
    DateTime.fromISO(event.startTime).diffNow().milliseconds <= 0
  ), [event.startTime]);

  const maxAmount = useMemo(() => {
    if (limitedQuantity) {
      return Math.min(maxGroupSize, quantity as number);
    }
    if (maxGroupSize) {
      return maxGroupSize;
    }
    return Infinity;
  }, [maxGroupSize, limitedQuantity, quantity]);

  const amountLeft = useMemo(() => (limitedQuantity
    ? quantity! - usedTickets : undefined), [limitedQuantity, quantity, usedTickets]);

  const maxAmountLeft = getMaxAmountLeft(maxAmount, amountLeft);

  const symbol = useMemo(() => (
    currencies.find((c) => c.code === currency.toUpperCase())?.symbolNative
  ), [currency]);

  const disabled = status === WaitingLineStatus.Inactive || (limitedQuantity && amountLeft! <= 0);

  const eventLanguage = event.languages[0] as Languages;

  const productDescriptionText = useMemo(() => {
    if (productDescription) {
      return productDescription[language] || productDescription[eventLanguage] || '';
    }
    return '';
  }, [productDescription, eventLanguage, language]);

  const nextDisabled = useMemo(() => (
    checkoutQuantity <= 0
    || checkoutQuantity > maxAmount
    || checkoutQuantity > (maxAmountLeft || 0)
  ), [checkoutQuantity, maxAmount, maxAmountLeft]);

  return (
    <>
      <TicketContainer $disabled={disabled}>
        <TickerHeader>
          <StatusBar
            status={status}
            hasMessage={Boolean(message)}
            setModalOpen={setModalOpen}
          />
          <Spacer />
          {!isFreemium && (
            <Price>
              {`${price.toFixed(2)}${symbol} ${currency.toUpperCase()}`}
            </Price>
          )}
        </TickerHeader>
        <TicketContent>
          <TicketIcon
            src={waitingLineType === WaitingLineType.Gold ? goldIcon : silverIcon}
          />
          <TicketContentText>
            <TicketTitle>
              {name[language] || name[eventLanguage]}
            </TicketTitle>
            <TicketDescription>
              {productDescriptionText}
            </TicketDescription>
          </TicketContentText>
        </TicketContent>
        <div>
          <TicketContent>
            {(!isFreemium || (isFreemium && limitedQuantity)) && (
              <QuantityInfos
                available={limitedQuantity ? amountLeft : undefined}
                maxAmount={maxAmount}
                showLimitPerTransaction={!isFreemium}
              />
            )}
          </TicketContent>
          <TicketActions>
            {!isFreemium && (
              <NumberInput
                value={checkoutQuantity}
                onChange={setCheckoutQuantity}
                max={maxAmountLeft}
                min={1}
                disabled={disabled}
              />
            )}
            <Spacer />
            <ButtonsContainer>
              {hasTimeSlots && (
                <OutlinedPrimaryButton
                  disabled={nextDisabled}
                  onClick={() => checkout({
                    amount: checkoutQuantity,
                    type: SessionType.TimeSlot,
                    waitingLineId: id,
                  })}
                >
                  <FormattedMessage id="tickets_reserve" />
                </OutlinedPrimaryButton>
              )}
              {isFreemium && (
                <PrimaryButton
                  disabled={nextDisabled || !eventStarted}
                  onClick={() => setLocation(`/waitingLine/${id}`)}
                >
                  <FormattedMessage id="tickets_enter" />
                </PrimaryButton>
              )}
              {!isFreemium && (
                <PrimaryButton
                  disabled={nextDisabled || !eventStarted}
                  onClick={() => checkout({
                    amount: checkoutQuantity,
                    type: SessionType.Payment,
                    waitingLineId: id,
                  })}
                >
                  <FormattedMessage id="tickets_checkout" />
                </PrimaryButton>
              )}
            </ButtonsContainer>
          </TicketActions>
        </div>
      </TicketContainer>
      {message && (
        <InformationModal
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          message={message}
        />
      )}
    </>
  );
}

export default Ticket;
