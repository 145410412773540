const defaultMuiThemeOptions = {
  fontSizes: {
    normal: '0.8em',
    big: '1em',
    bigger: '1.5em',
    biggest: '2.0em',
  },
  typography: {
    fontFamily: 'Montserrat, sans-serif',
    fontWeightLight: 400,
    fontWeightRegular: 500,
    fontWeightMedium: 600,
    fontWeightBold: 700,
  },
};

export default defaultMuiThemeOptions;
