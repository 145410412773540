import {
  Checkbox,
  FormControlLabel,
  formControlLabelClasses,
  inputBaseClasses,
  svgIconClasses,
  TextField,
} from '@mui/material';
import { MuiTelInput } from 'mui-tel-input';
import styled, { css } from 'styled-components';
import { PrimaryLinkButton } from 'styles/styles';

export const Text1 = styled.p`
  margin: 0;
  color: ${({ theme }) => theme.palette.custom.background.contrastText};
  text-align: center;
  font-size: ${({ theme }) => theme.fontSizes.biggest};
  font-weight: bold;
  transform: unset;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InputHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 5px 0;
`;

const InputStyles = css`
  & > .${inputBaseClasses.root} {
    background-color: ${({ theme }) => theme.palette.custom.input.background};
    color: ${({ theme }) => theme.palette.custom.input.color};
    border-radius: 8px;
  }
`;

export const EmailInput = styled(TextField)`
  ${InputStyles}
`;

export const TelInput = styled(MuiTelInput)`
  ${InputStyles}
`;

export const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const OptInCheckBoxContainer = styled.div`
  align-self: center;
  padding: 0 32px;
  margin-bottom: 16px;
`;

export const OptInLabel = styled(FormControlLabel)`
  color: ${({ theme }) => theme.palette.custom.background.contrastText};
  margin: 0;

  & .${formControlLabelClasses.label} {
    font-size: ${({ theme }) => theme.fontSizes.normal};
  }
`;

export const ContrastCheckBox = styled(Checkbox)`
  position: relative;

  & .${svgIconClasses.root} {
    z-index: 1;
  }

  /* Make the check the background contrast text color instead of transparent */
  ${({ checked }) => checked && css`
    &::before {
      content: "";
      position: absolute;
      background-color: ${({ theme }) => theme.palette.custom.input.background};
      width: 16px;
      height: 16px;
    }
  `}
`;

export const SwitchContactMethod = styled(PrimaryLinkButton)`
  color: ${({ theme }) => theme.palette.custom.background.contrastText};
`;

export const Circle = styled.div`
  background-color: ${({ theme }) => theme.palette.custom.secondaryAccent.main};
  height: 83svh;
  width: 83svh;
  border-radius: 50%;
  position: fixed;
  right: -20svw;
  bottom: -40svh;
  overflow: hidden;
`;

export const CircleContainer = styled.div`
  position: relative;
  width: 100%;
  height: 0;
`;
