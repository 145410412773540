import styled from 'styled-components';

export const DaySelectorContainer = styled.div`
  display: flex;
  overflow-x: auto;
  gap: 8px;
  padding: 4px;
  padding-bottom: 8px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.custom.lightGray};
  margin: 0 -16px;
`;

export const TimeSlotsContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 4px;
  margin: -1rem -4px;
  max-height: 30svh;
  gap: 8px;
`;

export const EmptyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const DaySpacer = styled.div`
  flex-grow: 1;
`;
