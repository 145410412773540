import {
  Button,
  Menu,
  menuClasses,
  menuItemClasses,
} from '@mui/material';
import styled from 'styled-components';

export const CenteredContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
`;

export const DropdownTrigger = styled(Button)`
  display: flex;
  justify-content: center;
  text-align: center;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.palette.custom.secondaryAccent.main};
  box-shadow: 0 0 1px 1px rgba(0 0 0 / 20%);

  &:hover {
    background: rgba(255 255 255 / 10%);
  }

  @media print {
    display: none;
  }
`;

export const DropdownText = styled.div`
  color: ${({ theme }) => theme.palette.custom.secondaryAccent.contrastText};
  font-weight: normal;
`;

export const IconContainer = styled.div`
  padding-left: 10px;
  color: white;
`;

export const MenuStyled = styled(Menu)`
  & .${menuClasses.list} {
    padding: 0;
  }

  & .${menuItemClasses.root} {
    background: ${({ theme }) => theme.palette.secondary.main};
    color: ${({ theme }) => theme.palette.secondary.contrastText};
  }

  & .${menuItemClasses.root}:hover {
    filter: brightness(0.9);
  }

  & .${menuItemClasses.selected} {
    filter: brightness(0.8);
  }

  & .${menuItemClasses.selected}:hover {
    filter: brightness(0.8);
    background-color: ${({ theme }) => theme.palette.secondary.main} !important;
  }
`;
