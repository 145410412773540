import { styled } from 'styled-components';

export const WaveBackgroundContainer = styled.div`
  position: fixed;
  top: 50svh;
  left: 0;
  width: 100svw;
  height: 100svh;
  padding: 0;
  margin: 0;
`;

export const Wave = styled.svg.attrs({
  preserveAspectRatio: 'xMidYMin slice',
})`
  fill: ${({ theme }) => theme.palette.custom.secondaryAccent.main};
  position: fixed;
  bottom: 0;
  left: 0;
  min-width: 100svw;
  max-height: 50svh;
`;
