import { useInfiniteQuery } from '@tanstack/react-query';
import Loader from 'components/Loading/Loader';
import { useGetAllWaitingLineTimeSlotsLazyQuery, useGetWaitingLineNameQuery, useReserveTimeSlotMutation } from 'lib/graphql/graphql';
import { TimeSlot } from 'lib/graphql/timeSlot/types';
import { DateTime } from 'luxon';
import { CardButtons } from 'modules/Cart/styles';
import { AlertContext } from 'providers/AlertProvider/AlertContext';
import useCart from 'providers/CartProvider/useCart';
import { TicketingContext } from 'providers/TicketingProvider/TicketingContext';
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { FormattedMessage } from 'react-intl';
import { OutlinedSecondaryButton, PrimaryButton } from 'styles/styles';

import DayItem from './components/DayItem/DayItem';
import TimeSlotItem from './components/TimeSlotItem/TimeSlotItem';
import {
  DaySelectorContainer,
  DaySpacer,
  EmptyContainer,
  TimeSlotsContainer,
} from './styles';

interface TimeSlotsProps {
  onBack: () => void
  onNext: () => void
}

function TimeSlots(props: TimeSlotsProps) {
  const { onBack, onNext } = props;

  const { order } = useContext(TicketingContext);
  const { setMessage } = useContext(AlertContext);
  const { sessionToken } = useCart();

  const [selectedDay, setSelectedDay] = useState(0);
  const [selectedSlot, setSelectedSlot] = useState('');

  const [reserveTimeSlot, { loading: reserving }] = useReserveTimeSlotMutation();
  const { data: waitingLineData, loading } = useGetWaitingLineNameQuery({
    variables: { id: order?.waitingLineId! },
    skip: !order?.waitingLineId,
  });
  const [getAllWaitingLineTimeSlots] = useGetAllWaitingLineTimeSlotsLazyQuery();
  const {
    data,
    fetchNextPage,
    hasNextPage,
    refetch,
    isLoading,
  } = useInfiniteQuery({
    queryKey: ['timeSlots', order?.waitingLineId],
    queryFn: async ({ pageParam }) => {
      const result = await getAllWaitingLineTimeSlots({
        fetchPolicy: 'network-only',
        variables: { waitingLineId: order!.waitingLineId, start: pageParam },
      });
      return result.data;
    },
    initialPageParam: undefined as string | undefined,
    getNextPageParam: (lastPage) => lastPage?.getAllWaitingLineTimeSlots.lastEvaluated,
  });

  useEffect(() => {
    const interval = setInterval(refetch, 60_000);
    return () => clearInterval(interval);
  }, [refetch]);

  useEffect(() => {
    if (hasNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage]);

  const days = useMemo(() => {
    if (!isLoading && data) {
      const today = DateTime.now().startOf('day');
      const slots = data.pages.flatMap((d) => d?.getAllWaitingLineTimeSlots.timeSlots || []);
      const daysMap: Record<number, TimeSlot[]> = {};
      slots.forEach((slot) => {
        const day = DateTime.fromISO(slot.startTime).startOf('day');
        if (day.diff(today).milliseconds >= 0) {
          const dayKey = day.toMillis();
          if (!daysMap[dayKey]) {
            daysMap[dayKey] = [];
          }
          daysMap[dayKey].push(slot);
        }
      });
      const newDays = Object.values(daysMap).sort((a, b) => {
        const aStart = DateTime.fromISO(a[0].startTime);
        const bStart = DateTime.fromISO(b[0].startTime);
        return aStart.diff(bStart).milliseconds;
      });
      return newDays.map((day) => (
        day.sort((a, b) => {
          const aStart = DateTime.fromISO(a.startTime);
          const bStart = DateTime.fromISO(b.startTime);
          return aStart.diff(bStart).milliseconds;
        })
      ));
    }
    return [];
  }, [data, isLoading]);

  const reserveSlot = useCallback(async () => {
    if (!sessionToken || !selectedSlot) return;

    try {
      await reserveTimeSlot({
        variables: {
          checkoutSession: sessionToken,
          slotId: selectedSlot,
        },
      });
      onNext();
    } catch (err) {
      const error = err as Error;
      if (error.message === 'WaitingLine is inactive') {
        setMessage('waiting_line_not_active');
      } else {
        setMessage('waiting_line_error');
      }
    }
  }, [onNext, reserveTimeSlot, selectedSlot, sessionToken, setMessage]);

  return (
    <Loader isLoading={isLoading || loading || reserving || !days[selectedDay]}>
      {days.length > 1 && (
        <DaySelectorContainer>
          <DaySpacer />
          {days.map((day, i) => (
            <DayItem
              key={day[0].id}
              day={day}
              onSelect={() => setSelectedDay(i)}
              selected={selectedDay === i}
              selectedSlot={selectedSlot}
            />
          ))}
          <DaySpacer />
        </DaySelectorContainer>
      )}
      <TimeSlotsContainer>
        {days[selectedDay]?.map((slot) => (
          <TimeSlotItem
            slot={slot}
            waitingLineName={waitingLineData?.getWaitingLine?.name}
            selectedSlot={selectedSlot}
            onSelect={setSelectedSlot}
          />
        ))}
      </TimeSlotsContainer>
      {days.length === 0 && (
        <EmptyContainer>
          <p>
            <FormattedMessage id="cart_reservation_empty" />
          </p>
          <PrimaryButton>
            <FormattedMessage id="alert_return" />
          </PrimaryButton>
        </EmptyContainer>
      )}
      <CardButtons>
        <OutlinedSecondaryButton onClick={onBack}>
          <FormattedMessage id="general_back" />
        </OutlinedSecondaryButton>
        <PrimaryButton
          onClick={reserveSlot}
          disabled={!selectedSlot}
        >
          <FormattedMessage id="general_next" />
        </PrimaryButton>
      </CardButtons>
    </Loader>
  );
}

export default TimeSlots;
