import EventLocalizedText from 'components/EventLocalizedText';
import { NullableText } from 'lib/graphql/graphql';
import { TimeSlot } from 'lib/graphql/timeSlot/types';
import { DateTime } from 'luxon';
import { TicketingContext } from 'providers/TicketingProvider/TicketingContext';
import { useContext, useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import {
  AmountLeft,
  Container,
  Infos,
  QueueName,
  SlotTime,
} from './styles';

interface TimeSlotItemProps {
  onSelect: (id: string) => void
  selectedSlot: string
  slot: TimeSlot
  waitingLineName?: NullableText
}

function TimeSlotItem(props: TimeSlotItemProps) {
  const {
    onSelect,
    selectedSlot,
    slot,
    waitingLineName,
  } = props;

  const { order } = useContext(TicketingContext);

  const remaining = slot.maxAmount - slot.reservedAmount;
  const full = remaining <= 0;
  const selected = selectedSlot === slot.id;
  const hasEnough = (order?.amount || 1) <= remaining;

  const startTime = useMemo(() => DateTime.fromISO(slot.startTime), [slot.startTime]);
  const endTime = useMemo(() => DateTime.fromISO(slot.endTime), [slot.endTime]);
  const isPast = useMemo(() => startTime.diffNow().milliseconds < 0, [startTime]);

  const start = useMemo(() => startTime.toFormat('T'), [startTime]);
  const end = useMemo(() => endTime.toFormat('T'), [endTime]);

  useEffect(() => {
    if (selected && full) {
      onSelect('');
    }
  }, [full, onSelect, selected]);

  return (
    <Container
      $selected={selected}
      disabled={full || isPast || !hasEnough}
      onClick={() => onSelect(slot.id)}
    >
      <Infos>
        <QueueName>
          {waitingLineName && <EventLocalizedText text={waitingLineName} />}
        </QueueName>
        <SlotTime>
          {start}
          {' - '}
          {end}
        </SlotTime>
      </Infos>
      {!isPast && (
        <AmountLeft
          $selected={selected}
          $full={full}
        >
          <FormattedMessage
            id={full ? 'cart_reservation_slot_full' : 'cart_reservation_slot_remaining'}
            values={{ remaining }}
          />
        </AmountLeft>
      )}
    </Container>
  );
}

export default TimeSlotItem;
