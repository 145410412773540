import { createTheme, PaletteOptions } from '@mui/material';

import defaultMuiThemeOptions from '../defaultMuiThemeOptions';
import { AppTheme } from '../types/AppTheme';

const palette: PaletteOptions = {
  mode: 'light',
  primary: {
    main: '#3c6e71',
    contrastText: '#fff',
  },
  secondary: {
    main: '#3c6e71',
    contrastText: '#fff',
  },
  background: {
    default: '#e0a458',
    paper: '#fff',
  },
  text: {
    primary: '#000',
  },
  error: {
    main: '#e85050',
    contrastText: '#ffffff',
  },
  warning: {
    main: '#f67044',
    contrastText: '#ffffff',
  },
  success: {
    main: '#72d59c',
    contrastText: '#ffffff',
  },
  custom: {
    background: {
      contrastText: '#ffffff',
      dark: '#001f3e',
    },
    upgradeBlock: {
      background: '#00000044',
      contrastText: '#fff',
      arrow: '#00000066',
    },
    lightGray: '#eeeeee',
    gray: '#4D4F53',
    secondaryAccent: {
      altContrastText: '#3c6e71',
      main: '#ffffff33',
      contrastText: '#fff',
    },
    input: {
      background: '#eeeeee',
      color: '#000',
    },
  },
};

const theme: AppTheme = {
  name: 'Earth Yellow',
  theme: createTheme({
    ...defaultMuiThemeOptions,
    palette,
  }),
};

export default theme;
