import styled from 'styled-components';
import { hexToRgbString, ResponsiveCloudImg } from 'styles/styles';

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px
`;

export const Container = styled.div`
  width: 100%;
  gap: 1rem;
  padding: 16px;
  padding-top: calc(22vh + 1rem);
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  z-index: 10;
  flex-grow: 1;
`;

interface TicketProps {
  $disabled: boolean;
}

export const TicketContainer = styled.div<TicketProps>`
  background: ${({ $disabled: $isDisabled, theme }) => ($isDisabled
    ? `rgba(${hexToRgbString(theme.palette.background.paper)} / 80%)`
    : theme.palette.background.paper)};
  border-radius: 16px;
  padding: 16px 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 550px;
  width: 100%;

  ${({ $disabled }) => $disabled && `
    user-select: none;
  `}
`;

export const TickerHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
`;

export const Price = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.bigger};
  color: black;
  margin-right: 16px;
`;

export const TicketContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 0 16px;
  gap: 0.75rem;
`;

export const TicketIcon = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: contain;
  padding: 4px;
`;

export const TicketContentText = styled.div`
  display: flex;
  text-align: left;
  flex-direction: column;
  justify-content: center;
`;

export const TicketTitle = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.big};
  color: black;
  font-weight: bold;
  word-break: break-word;
`;

export const TicketDescription = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.normal};
  color: black;
  line-height: 1;
  word-break: break-word;
`;

export const TicketActions = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  padding: 0 16px;
  gap: 4px;
`;

export const RadiantImage = styled.div`
  position: fixed;
  width: 100%;
  height: 22vh;
  background:
    radial-gradient(
      circle,
      #ffa200,
      transparent 50vw
    ),
    #f67044;
`;

export const ImageContainer = styled(ResponsiveCloudImg)`
  position: fixed;
  width: 100%;
  height: 22vh;
  object-fit: contain;
  align-self: center;
`;

export const Spacer = styled.div`
  flex-grow: 1;
`;
