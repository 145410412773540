import ErrorBoundary from 'components/ErrorPage/ErrorBoundary';
import SuspenseLoader from 'components/Loading/SuspenseLoader';
import SavedTransactionChecker from 'components/SavedTransactionChecker';
import Home from 'modules/Home/Home';
import Login from 'modules/Login/Login';
import Onboarding from 'modules/OnBoarding/Onboarding';
import Support from 'modules/Support/Support';
import AlertProvider from 'providers/AlertProvider/AlertProvider';
import EventProvider from 'providers/EventProvider/EventProvider';
import MetadataProvider from 'providers/MetadataProvider/MetadataProvider';
import PreviewProvider from 'providers/PreviewProvider/PreviewProvider';
import { Route, Router, Switch } from 'wouter';

import TicketingRoutes from './TicketingRoutes';

function Routes() {
  return (
    <Router>
      <ErrorBoundary>
        <SuspenseLoader>
          <SavedTransactionChecker>
            <MetadataProvider>
              <Switch>
                <Route path="/login" component={Login} />
                <Route path="/onboarding" component={Onboarding} />
                <Route path="/event/:eventId" nest>
                  <EventProvider>
                    <PreviewProvider>
                      <AlertProvider>
                        <TicketingRoutes />
                      </AlertProvider>
                    </PreviewProvider>
                  </EventProvider>
                </Route>
                <Route path="/support" component={Support} />
                <Route component={Home} />
              </Switch>
            </MetadataProvider>
          </SavedTransactionChecker>
        </SuspenseLoader>
      </ErrorBoundary>
    </Router>
  );
}

export default Routes;
