import {
  Wave,
  WaveBackgroundContainer,
} from './styles';

export default function WaveBackground() {
  return (
    <WaveBackgroundContainer>
      <Wave xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path d="M0 193.8V45.2c.2-9.1-.3-18.1 .2-26.6c.1-2.3 23.8-8.4 29.9-10.4c31.5-10.8 67.2-10.9 103.8-.2c23.6 6.8 47.7 18 72.3 33.7c12.4 7.9 28.9 19.9 41.4 30.3c33.8 28.1 73.6 41.1 118.4 38.7c35.7-1.9 72-12.9 104.3-31.4c15.7-9 31.2-21.7 37.3-30.5c1.3-1.9 2.1-2.5 2.9-2.4l1.4-.1 .3 148.7-.1 317.5-243.1 1-269.2-1.1z" />
      </Wave>
    </WaveBackgroundContainer>
  );
}
