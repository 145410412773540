import { defaultTheme } from 'app-themes';
import { createContext } from 'react';

export interface IAppThemeContext {
  theme: string;
  setTheme: (theme: string) => void;
}

export const DefaultAppThemeContext: IAppThemeContext = {
  theme: defaultTheme.name,
  setTheme: () => {},
};

export const AppThemeContext = createContext(DefaultAppThemeContext);
