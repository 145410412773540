import { ShoppingCart } from '@mui/icons-material';
import {
  Dialog,
} from '@mui/material';
import SuspenseLoader from 'components/Loading/SuspenseLoader';
import { useReleaseTimeSlotMutation } from 'lib/graphql/graphql';
import useCart from 'providers/CartProvider/useCart';
import { EventContext } from 'providers/EventProvider/EventContext';
import { TicketingContext } from 'providers/TicketingProvider/TicketingContext';
import {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { FormattedMessage } from 'react-intl';
import { DangerButton, OutlinedSecondaryButton } from 'styles/styles';

import Communication from './components/Communication/Communication';
import CountdownTimer from './components/CountdownTimer/CountdownTimer';
import Impact from './components/Impact/Impact';
import Payment from './components/Payment/Payment';
import Summary from './components/Summary/Summary';
import TimeSlots from './components/TimeSlots/TimeSlots';
import {
  CancelDialogCardContent,
  CancelDialogCardHeader,
  Card,
  CardTitle,
  CenteredCardActions,
  Container,
  Step,
  StepConnector,
  StepConnectorLine,
  StepIconContainer,
  Stepper,
  StepText,
} from './styles';

type CartSteps = 'summary' | 'reservation' | 'impact' | 'communication' | 'payment';

function Cart() {
  const {
    sessionToken,
    createCheckoutSession,
    cancelTickets,
    reserveTickets,
    setObnlId,
    stripeCheckoutReturnUrl,
  } = useCart();
  const { clearOrder, order } = useContext(TicketingContext);
  const event = useContext(EventContext);

  const [releaseTimeSlot] = useReleaseTimeSlotMutation();

  const [currentStep, setCurrentStep] = useState<CartSteps>('summary');
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleBackButtonEvent = useCallback((e: PopStateEvent) => {
    e.preventDefault();
    setDialogOpen(true);
    // eslint-disable-next-line no-restricted-globals
    history.pushState(null, document.title, location.href);
  }, []);

  const handleBeforeUnloadEvent = useCallback((e: BeforeUnloadEvent) => {
    e.preventDefault();
    e.returnValue = ''; // Chrome requires returnValue to be set
    setDialogOpen(true);
  }, []);

  useEffect(() => {
    // Push a "dummy" entry onto the history stack before we start listening for 'popstate' events
    if (currentStep === 'impact' || currentStep === 'payment') {
      // eslint-disable-next-line no-restricted-globals
      history.pushState(null, document.title, location.href);

      window.addEventListener('popstate', handleBackButtonEvent);
      window.addEventListener('beforeunload', handleBeforeUnloadEvent);
    }

    return () => {
      window.removeEventListener('popstate', handleBackButtonEvent);
      window.removeEventListener('beforeunload', handleBeforeUnloadEvent);
    };
  }, [currentStep, handleBackButtonEvent, handleBeforeUnloadEvent]);

  const handleCancelDialog = useCallback(() => {
    setDialogOpen(false);
  }, [setDialogOpen]);

  const handleAcceptDialog = useCallback(async () => {
    clearOrder();
    if (order?.type !== 'Freemium') {
      await cancelTickets();
    }
    setDialogOpen(false);
    setCurrentStep('summary');
  }, [cancelTickets, clearOrder, order?.type]);

  const finalSteps = useMemo(() => {
    const steps: (CartSteps | false)[] = [
      'summary',
      order?.type === 'TimeSlot' && 'reservation',
      event.allowObnlChoice && 'impact',
      order?.type !== 'Freemium' && 'communication',
      'payment',
    ];
    return steps.filter(Boolean) as CartSteps[];
  }, [event.allowObnlChoice, order?.type]);

  const toNextStep = useCallback(async () => {
    const nextStep = finalSteps[finalSteps.indexOf(currentStep) + 1];
    if (nextStep) {
      if (nextStep === 'payment') {
        await createCheckoutSession();
      }
      setCurrentStep(nextStep);
    }
  }, [createCheckoutSession, currentStep, finalSteps]);

  const toPreviousStep = useCallback(async () => {
    const previousStep = finalSteps[finalSteps.indexOf(currentStep) - 1];
    if (previousStep) {
      if (previousStep === 'reservation' && sessionToken) {
        await releaseTimeSlot({ variables: { checkoutSession: sessionToken } });
      }

      if (previousStep === 'summary') {
        setDialogOpen(true);
      } else {
        setCurrentStep(previousStep);
      }
    }
  }, [currentStep, finalSteps, releaseTimeSlot, sessionToken]);

  if (!order) return null;

  return (
    <>
      {currentStep !== 'summary' && (
        <CountdownTimer />
      )}
      <Container>
        <Stepper $hasTimer={currentStep !== 'summary'}>
          {finalSteps.map((step) => (
            <Fragment key={step}>
              <Step>
                {step === currentStep && (
                  <StepIconContainer>
                    <ShoppingCart />
                  </StepIconContainer>
                )}
                <StepText $active={step === currentStep}>
                  <FormattedMessage id={`cart_${step}`} />
                </StepText>
              </Step>
              {step !== 'payment' && (
                <StepConnector>
                  <StepConnectorLine />
                </StepConnector>
              )}
            </Fragment>
          ))}
        </Stepper>
        <Card $lessPadding={currentStep === 'payment'}>
          <CardTitle>
            <FormattedMessage id={`cart_${currentStep}`} />
          </CardTitle>
          {currentStep === 'summary' && (
            <SuspenseLoader>
              <Summary
                onNext={async () => {
                  await reserveTickets(order);
                  if (!event.allowObnlChoice) {
                    setObnlId(event.obnls?.[0]);
                  }
                  await toNextStep();
                }}
              />
            </SuspenseLoader>
          )}
          {currentStep === 'reservation' && (
            <TimeSlots
              onBack={toPreviousStep}
              onNext={toNextStep}
            />
          )}
          {currentStep === 'impact' && (
            <Impact
              onBack={toPreviousStep}
              onNext={toNextStep}
            />
          )}
          {currentStep === 'communication' && (
            <Communication
              onBack={toPreviousStep}
              onNext={toNextStep}
            />
          )}
          {currentStep === 'payment' && (
            <Payment
              onBack={toPreviousStep}
              onComplete={() => {
                window.removeEventListener('popstate', handleBackButtonEvent);
                window.removeEventListener('beforeunload', handleBeforeUnloadEvent);
                if (stripeCheckoutReturnUrl) window.location.href = stripeCheckoutReturnUrl;
              }}
            />
          )}
        </Card>
      </Container>
      <Dialog open={dialogOpen}>
        <CancelDialogCardHeader title={<FormattedMessage id="cancel_order_title" />} />
        <CancelDialogCardContent>
          <FormattedMessage id="cancel_order_body" />
        </CancelDialogCardContent>
        <CenteredCardActions>
          <OutlinedSecondaryButton onClick={handleCancelDialog}>
            <FormattedMessage id="general_no" />
          </OutlinedSecondaryButton>
          <DangerButton onClick={handleAcceptDialog}>
            <FormattedMessage id="general_yes" />
          </DangerButton>
        </CenteredCardActions>
      </Dialog>
    </>
  );
}

export default Cart;
