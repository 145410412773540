import { PropsWithChildren } from 'react';
import { useRoute } from 'wouter';

import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import { Container, Content } from './styles';

interface LayoutProps extends PropsWithChildren {
  className?: string
  title?: React.ReactNode
}

function Layout(props: LayoutProps) {
  const { className, children, title } = props;
  const [isHome] = useRoute('/');

  return (
    <Container className={className}>
      <Header title={title} showLogo={!isHome} />
      <Content>
        {children}
        <Footer isHome={isHome} />
      </Content>
    </Container>
  );
}

export default Layout;
