import styled, { css } from 'styled-components';
import { BaseButton } from 'styles/styles';

interface ContainerProps {
  $selected: boolean
}

export const Container = styled(BaseButton)<ContainerProps>`
  display: flex;
  justify-content: space-between;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.palette.custom.gray};
  padding: 8px;
  font-weight: normal;
  background-color: transparent;

  ${({ $selected }) => $selected && css`
    background-color: ${({ theme }) => theme.palette.secondary.main};
    border-color: ${({ theme }) => theme.palette.custom.secondaryAccent.main};
    color: ${({ theme }) => theme.palette.secondary.contrastText};
  `}

  :disabled {
    background-color: transparent;
    border-color: ${({ theme }) => theme.palette.custom.lightGray};
    color: ${({ theme }) => theme.palette.custom.lightGray};
  }
`;

export const Infos = styled.div`
  padding-left: 8px;
`;

export const QueueName = styled.p`
  margin: 0;
  font-weight: bold;
  text-align: left;
`;

export const SlotTime = styled.p`
  margin: 0;
  padding-left: 0;
`;

interface AmountLeftProps {
  $full: boolean
  $selected: boolean
}

export const AmountLeft = styled.span<AmountLeftProps>`
  font-weight: bold;
  color: ${({ $selected, theme }) => ($selected ? theme.palette.secondary.contrastText : theme.palette.secondary.main)};
  text-transform: uppercase;

  ${({ $full }) => $full && css`
    color: initial;
  `}
`;
