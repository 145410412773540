import { useBuyTicketsMutation, useCancelTicketsMutation, useReserveTicketsMutation } from 'lib/graphql/graphql';
import { DateTime } from 'luxon';
import { EventContext } from 'providers/EventProvider/EventContext';
import useMetadata from 'providers/MetadataProvider/useMetadata';
import {
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useIntl } from 'react-intl';

import { AlertContext } from '../AlertProvider/AlertContext';
import { TicketingContext } from '../TicketingProvider/TicketingContext';
import CartContext, { DefaultCartContext, ICartContext } from './CartContext';

export interface CartProviderProps extends PropsWithChildren { }

function CartProvider(props: CartProviderProps) {
  const { children } = props;

  const event = useContext(EventContext);
  const { setMessage } = useContext(AlertContext);
  const { order: providerOrder, clearOrder: cancelOrder } = useContext(TicketingContext);
  const { locale } = useIntl();
  const [sessionToken, setSessionToken] = useState(DefaultCartContext.sessionToken);
  const [expirationTime, setExpirationTime] = useState(DefaultCartContext.expirationTime);
  const [
    stripeCheckoutSessionClientSecret,
    setStripeCheckoutSessionClientSecret,
  ] = useState<string | null>(null);
  const metadata = useMetadata();

  const [stripeCheckoutReturnUrl, setStripeCheckoutReturnUrl] = useState<string | null>(null);
  const [reserveTicketsMutation] = useReserveTicketsMutation();
  const [cancelTicketsMutation] = useCancelTicketsMutation();
  const [buyTicketsMutation] = useBuyTicketsMutation();

  const obnlIdRef = useRef<string | null>(null);
  const [communication, setCommunication] = useState(DefaultCartContext.communication);
  const [
    optInNotifications,
    setOptInNotifications,
  ] = useState(DefaultCartContext.optInNotifications);

  const reserveTickets = useCallback<ICartContext['reserveTickets']>(async (order) => {
    try {
      const { data } = await reserveTicketsMutation({
        variables: {
          fields: {
            type: order.type,
            eventId: event.id,
            waitingLineId: order.waitingLineId,
            quantity: order.amount,
            transactionId: order.transactionId,
            consumerLocale: locale,
            ...(metadata ? { metadata: JSON.stringify(metadata || '{}') } : {}),
          },
        },
      });
      if (data) {
        if (data.reserveTickets.timer) {
          setExpirationTime(DateTime.fromISO(data.reserveTickets.timer));
        }
        setSessionToken(data.reserveTickets.sessionToken);
      } else {
        cancelOrder();
      }
    } catch (err) {
      const error = err as Error;
      if (error.message === 'WaitingLine is inactive') {
        setMessage('waiting_line_not_active');
      } else if (error.message === 'Not enough tickets available to reserve') {
        setMessage('waiting_line_not_enough_tickets');
      }
      cancelOrder();
    }
  }, [cancelOrder, event.id, locale, reserveTicketsMutation, setMessage, metadata]);

  const cancelTickets = useCallback(async () => {
    if (!sessionToken) return;
    await cancelTicketsMutation({
      variables: { sessionToken },
    });
    setSessionToken(null);
  }, [cancelTicketsMutation, sessionToken]);

  const createCheckoutSession = useCallback(async () => {
    if (!sessionToken) return;
    const { data } = await buyTicketsMutation({
      variables: {
        ...(providerOrder?.transactionId
          ? { transactionId: providerOrder.transactionId }
          : { contactInfo: { optInNotifications, type: communication, preferredLocale: locale } }
        ),
        sessionToken,
        obnlId: obnlIdRef.current ?? '',
      },
    });
    setStripeCheckoutSessionClientSecret(data?.buyTickets.clientSecret ?? null);
    setStripeCheckoutReturnUrl(data?.buyTickets.returnUrl ?? null);
  }, [
    buyTicketsMutation,
    communication,
    optInNotifications,
    providerOrder?.transactionId,
    sessionToken,
    locale,
  ]);

  const setObnlId = useCallback((impact: string | null) => {
    obnlIdRef.current = impact;
  }, []);

  const context = useMemo<ICartContext>(() => ({
    sessionToken,
    communication,
    optInNotifications,
    reserveTickets,
    setCommunication,
    setOptInNotifications,
    stripeCheckoutReturnUrl,
    stripeCheckoutSessionClientSecret,
    createCheckoutSession,
    cancelTickets,
    expirationTime,
    setExpirationTime,
    obnlId: obnlIdRef.current,
    setObnlId,
  }), [
    sessionToken,
    communication,
    optInNotifications,
    reserveTickets,
    stripeCheckoutReturnUrl,
    stripeCheckoutSessionClientSecret,
    createCheckoutSession,
    cancelTickets,
    expirationTime,
    setObnlId,
  ]);

  return (
    <CartContext.Provider value={context}>
      {children}
    </CartContext.Provider>
  );
}

export default CartProvider;
