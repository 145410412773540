import { QueueType, TicketStatus, useGetTicketFromTransactionQuery } from 'lib/graphql/graphql';
import { clearStoredTransaction, getStoredTransaction } from 'lib/stores/transactions';
import {
  PropsWithChildren,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLocation, useSearch } from 'wouter';

import CenteredSpinner from './Loading/CenteredSpinner';

function SavedTransactionChecker(props: PropsWithChildren) {
  const { children } = props;
  const searchString = useSearch();

  const params = new URLSearchParams(searchString);
  const isPreview = params.has('preview');
  const [, setLocation] = useLocation();
  const savedTransaction = useMemo(() => {
    if (isPreview) {
      return null;
    }
    return getStoredTransaction();
  }, [isPreview]);

  const [checking, setChecking] = useState(true);

  const { data, loading, error } = useGetTicketFromTransactionQuery({
    variables: { transactionId: savedTransaction?.transactionId! },
    skip: !savedTransaction?.transactionId,
    fetchPolicy: 'network-only',
  });

  const tickets = data?.getTicketFromTransaction.tickets;
  const hasUnusedTickets = useMemo(() => (
    tickets && tickets.length > 0
    && tickets.some((t) => t.status === TicketStatus.Activated)
  ), [tickets]);

  useEffect(() => {
    if (!loading) {
      if (hasUnusedTickets) {
        const ticket = tickets![0];
        if (savedTransaction?.isFreemium) {
          if (ticket.queueType === QueueType.Called) {
            setLocation(`/event/${ticket.eventId}/called?id=${savedTransaction?.transactionId}`);
          } else {
            setLocation(`/event/${ticket.eventId}/wait?id=${savedTransaction?.transactionId}`);
          }
        } else {
          setLocation(`/event/${ticket.eventId}/confirmation?id=${savedTransaction?.transactionId}`);
        }
      } else {
        clearStoredTransaction();
      }
      setChecking(false);
    }
  }, [hasUnusedTickets, loading, savedTransaction, setLocation, tickets]);

  useEffect(() => {
    if (error) {
      clearStoredTransaction();
    }
  }, [error]);

  if (checking) return <CenteredSpinner />;
  return children;
}

export default SavedTransactionChecker;
