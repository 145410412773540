import { skipToken } from '@apollo/client';
import { faUpgrade } from '@awesome.me/kit-15c513ff27/icons/kit/custom';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { useAse, useAseMessages } from '@novominteractive/ase-react-apollo';
import WaveBackground from 'components/Layout/components/WaveBackground/WaveBackground';
import TimeSlotWaitInfos from 'components/TimeSlotWaitInfos';
import { currenciesMap } from 'lib/currencies';
import {
  QueueType,
  SessionType,
  useAseLoginMutation,
  useGetTicketFromTransactionSuspenseQuery,
  useGetTicketWaitingTimeQuery,
  useGetWaitingLinePremiumWaitingTimeQuery,
  useGetWaitingLineSuspenseQuery,
} from 'lib/graphql/graphql';
import { storeTransaction } from 'lib/stores/transactions';
import { TicketingContext } from 'providers/TicketingProvider/TicketingContext';
import {
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'wouter';

import FreemiumLayout from '../components/FreemiumLayout/FreemiumLayout';
import TicketNumber from '../components/TicketNumber/TicketNumber';
import {
  Price,
  SavedTime,
  Spacer,
  Time,
  UpgradeBackgroundIcon,
  UpgradeBackgroundImage,
  UpgradeButton,
  UpgradeButtonContainer,
  UpgradeButtonIcon,
  UpgradeContainer,
  UpgradeFor,
  UpgradeSmallPrint,
  Welcome,
} from './styles';

interface TicketQueueType {
  queueType: QueueType;
}

function PriceChunk(chunks: React.ReactNode) {
  return <Price>{chunks}</Price>;
}

function Wait() {
  const [, setLocation] = useLocation();
  const { checkout } = useContext(TicketingContext);
  const [aseLogin] = useAseLoginMutation();
  const { loggedIn, login } = useAse();

  const transactionId = useMemo(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    return urlSearchParams.get('id');
  }, []);

  const { data, refetch } = useGetTicketFromTransactionSuspenseQuery(
    transactionId ? {
      variables: { transactionId },
      fetchPolicy: 'network-only',
      queryKey: 'get-transaction-wait',
    } : skipToken,
  );

  const tickets = useMemo(() => data?.getTicketFromTransaction.tickets || [], [data]);
  const waitingLineId = tickets[0]?.waitingLineId;

  useEffect(() => {
    async function connectToAse() {
      if (!loggedIn) {
        const { data: aseLoginData } = await aseLogin();
        if (aseLoginData?.aseLogin) {
          login(aseLoginData.aseLogin);
        }
      }
    }
    connectToAse();
  }, [loggedIn, login, aseLogin]);

  useAseMessages<TicketQueueType>(
    `${tickets[0]?.waitingLineId}/${tickets[0]?.id}`,
    (payload) => {
      if (payload.queueType === QueueType.Called) {
        setLocation(`/called?id=${transactionId}`);
      }
    },
    !loggedIn || !tickets[0]?.id || !tickets[0]?.waitingLineId,
  );

  const { data: waitingLineData } = useGetWaitingLineSuspenseQuery(
    waitingLineId ? {
      variables: { id: waitingLineId },
    } : skipToken,
  );

  const { data: premiumWaitData } = useGetWaitingLinePremiumWaitingTimeQuery({
    variables: { id: waitingLineId! },
    skip: !waitingLineId,
    fetchPolicy: 'network-only',
    pollInterval: 60_000, // every minutes
  });
  const { data: ticketWaitData } = useGetTicketWaitingTimeQuery({
    variables: {
      ticketId: data?.getTicketFromTransaction.tickets[0]?.id!,
      waitingLineId: waitingLineId!,
    },
    skip: !waitingLineId || !data?.getTicketFromTransaction.tickets[0]?.id,
    fetchPolicy: 'network-only',
    pollInterval: 60_000, // every minutes
  });
  const waitingLine = waitingLineData?.getWaitingLine;

  const upgraded = tickets[0].queueType === QueueType.Premium;
  const called = tickets[0].queueType === QueueType.Called;
  const timeSlot = tickets[0].timeSlot || undefined;

  useEffect(() => {
    if (tickets.length > 0 && transactionId) {
      storeTransaction({ isFreemium: true, transactionId });
    }
  }, [tickets.length, transactionId]);

  useEffect(() => {
    if (!transactionId || !waitingLine) {
      setLocation('/tickets');
    }
  }, [setLocation, transactionId, waitingLine]);

  useEffect(() => {
    if (called) {
      setLocation(`/called?id=${transactionId}`);
    }
  }, [called, setLocation, transactionId]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      refetch();
    }, 60_000); // every minutes

    return () => clearInterval(intervalId);
  }, [refetch]);

  if (!transactionId || !waitingLine) {
    return null;
  }

  const { currency, price } = waitingLine;
  const symbol = currenciesMap[currency.toUpperCase()].symbolNative;

  const premiumWaitTime = Math.max(
    premiumWaitData?.getWaitingLine?.premiumQueueWaitingTime || -1,
    0,
  );
  const ticketWaitTime = Math.max(ticketWaitData?.getTicketWaitTime || -1, 0);
  const savedTime = Math.max(ticketWaitTime - premiumWaitTime, 0);

  const { displayId } = tickets[0];

  return (
    <>
      <WaveBackground />
      <FreemiumLayout>
        <div>
          <Welcome>
            <FormattedMessage id="waiting_line_waiting_welcome" />
          </Welcome>
          <Time>
            {timeSlot ? (
              <TimeSlotWaitInfos infos={timeSlot} />
            ) : (
              <FormattedMessage
                id="waiting_line_waiting_estimated_wait"
                values={{ minutes: Math.round(ticketWaitTime / 60_000) }}
              />
            )}
          </Time>
        </div>
        <TicketNumber ticketNumber={displayId.toString()} />
        <Spacer />
        {!upgraded && !timeSlot && (
          <UpgradeContainer>
            <UpgradeBackgroundImage>
              <UpgradeBackgroundIcon icon={faUpgrade as IconProp} />
            </UpgradeBackgroundImage>
            <SavedTime>
              <FormattedMessage
                id="waiting_line_waiting_saved_time"
                values={{ minutes: Math.round(savedTime / 60_000) }}
              />
            </SavedTime>
            <UpgradeFor>
              <FormattedMessage
                id="waiting_line_waiting_price"
                values={{
                  price: `${price?.toFixed(2)}${symbol}`,
                  Price: PriceChunk,
                }}
              />
            </UpgradeFor>

            <UpgradeButton
              onClick={() => {
                checkout({
                  amount: 1,
                  type: SessionType.Freemium,
                  transactionId,
                  waitingLineId: waitingLineId!,
                });
              }}
            >
              <UpgradeButtonContainer>
                <UpgradeButtonIcon icon={faUpgrade as IconProp} />
                <FormattedMessage id="waiting_line_waiting_upgrade" />
              </UpgradeButtonContainer>
            </UpgradeButton>
            <UpgradeSmallPrint>
              <FormattedMessage id="waiting_line_waiting_estimated_disclaimer" />
            </UpgradeSmallPrint>
          </UpgradeContainer>
        )}
      </FreemiumLayout>
    </>
  );
}

export default Wait;
