import { TicketTimeSlotInfos } from 'lib/graphql/graphql';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

interface TimeSlotWaitInfosProps {
  infos: TicketTimeSlotInfos
}

function TimeSlotWaitInfos(props: TimeSlotWaitInfosProps) {
  const { infos } = props;

  const { locale } = useIntl();

  const date = useMemo(() => (
    DateTime.fromISO(infos.startTime).setLocale(locale).toFormat('DDD')
  ), [infos.startTime, locale]);
  const start = useMemo(() => (
    DateTime.fromISO(infos.startTime).setLocale(locale).toFormat('T')
  ), [infos.startTime, locale]);
  const end = useMemo(() => (
    DateTime.fromISO(infos.endTime).setLocale(locale).toFormat('T')
  ), [infos.endTime, locale]);

  return (
    <FormattedMessage
      id="waiting_line_waiting_time_slot"
      values={{
        date,
        start,
        end,
      }}
    />
  );
}

export default TimeSlotWaitInfos;
