import {
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSearch } from 'wouter';

import MetadataContext, { IMetadataContext } from './MetadataContext';

export interface MetadataProviderProps {
  children: JSX.Element
}

export default function MetadataProvider(props: MetadataProviderProps) {
  const { children } = props;
  const searchString = useSearch();

  const [metadata, setMetadata] = useState({});

  useEffect(() => {
    if (searchString) {
      const params = new URLSearchParams(searchString);
      const newMetadata: Record<string, string> = {};
      params.forEach((value, key) => {
        if (key.startsWith('meta-') && key.length > 5) {
          const newKey = key.slice(5);
          newMetadata[newKey] = value;
        }
      });
      setMetadata(newMetadata);
    }
  }, [searchString]);

  const contextValue = useMemo<IMetadataContext>(() => (metadata), [metadata]);

  return (
    <MetadataContext.Provider value={contextValue}>
      {children}
    </MetadataContext.Provider>
  );
}
