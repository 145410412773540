import {
  ThemeProvider,
} from '@mui/material/styles';
import { appThemesMap, defaultTheme } from 'app-themes';
import { useCallback, useMemo, useState } from 'react';

import { AppThemeContext, DefaultAppThemeContext } from './AppThemeContext';
import { Background } from './styles';

function AppThemeProvider({ children }: { children: JSX.Element }) {
  const [themeName, setThemeName] = useState<string>(DefaultAppThemeContext.theme);

  const setTheme = useCallback((themeId: string) => {
    setThemeName(themeId);
  }, []);

  const theme = useMemo(() => {
    const appTheme = appThemesMap[themeName];
    if (!appTheme) {
      return defaultTheme.theme;
    }
    return appTheme.theme;
  }, [themeName]);

  const contextValue = useMemo(() => ({
    theme: themeName,
    setTheme,
  }), [themeName, setTheme]);

  return (
    <AppThemeContext.Provider value={contextValue}>
      <ThemeProvider theme={theme}>
        <Background>
          {children}
        </Background>
      </ThemeProvider>
    </AppThemeContext.Provider>
  );
}

export default AppThemeProvider;
