import { faArrowRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CircularProgress } from '@mui/material';
import { ContactInfoType, useClaimFreeTicketsMutation } from 'lib/graphql/graphql';
import { storeTransaction } from 'lib/stores/transactions';
import { AlertContext } from 'providers/AlertProvider/AlertContext';
import { EventContext } from 'providers/EventProvider/EventContext';
import useMetadata from 'providers/MetadataProvider/useMetadata';
import {
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ErrorMessage, RoundedContainedButton } from 'styles/styles';
import { useLocation, useParams } from 'wouter';

import FreemiumLayout from '../components/FreemiumLayout/FreemiumLayout';
import {
  Circle,
  CircleContainer,
  ContrastCheckBox,
  EmailInput,
  InputContainer,
  InputHeader,
  OptInCheckBoxContainer,
  OptInLabel,
  SpinnerContainer,
  SwitchContactMethod,
  TelInput,
  Text1,
} from './styles';

function Register() {
  const { waitingLineId } = useParams();
  const [, setLocation] = useLocation();
  const { formatMessage } = useIntl();

  const { id: eventId } = useContext(EventContext);
  const { setMessage } = useContext(AlertContext);
  const [claimFreeTickets, { loading }] = useClaimFreeTicketsMutation();
  const { locale } = useIntl();

  const [useSms, setUseSms] = useState(false);
  const [communicationAddress, setCommunicationAddress] = useState('');
  const [optInNotifications, setOptInNotifications] = useState(false);
  const [formError, setFormError] = useState('');
  const metadata = useMetadata();

  useEffect(() => {
    if (!waitingLineId) {
      setLocation('/tickets');
    }
  }, [setLocation, waitingLineId]);

  const toggleSms = useCallback(() => {
    setCommunicationAddress('');
    setUseSms(!useSms);
  }, [useSms]);

  const register = useCallback(async () => {
    setFormError('');
    if (!communicationAddress) {
      setFormError('generic_form_error_required');
      return;
    }
    if (!useSms && !communicationAddress.includes('@')) {
      setFormError('waiting_line_claim_free_invalid_email');
      return;
    }

    try {
      const result = await claimFreeTickets({
        variables: {
          fields: {
            contactInfo: {
              type: useSms ? ContactInfoType.Phone : ContactInfoType.Email,
              value: communicationAddress,
              optInNotifications,
              preferredLocale: locale,
            },
            eventId,
            quantity: 1,
            waitingLineId: waitingLineId!,
            ...(metadata ? { metadata: JSON.stringify(metadata) } : {}),
          },
        },
      });
      const transactionId = result.data?.claimFreeTickets;
      if (transactionId) {
        storeTransaction({ isFreemium: true, transactionId });
        setLocation(`/wait?id=${transactionId}`);
      } else {
        setMessage('waiting_line_error');
      }
    } catch (err) {
      const error = err as Error;
      if (error.message === 'WaitingLine is inactive') {
        setMessage('waiting_line_not_active');
      } else if (error.message === 'Not enough tickets available to reserve') {
        setMessage('waiting_line_not_enough_tickets');
      } else {
        setMessage('waiting_line_error');
      }
    }
  }, [
    claimFreeTickets,
    communicationAddress,
    eventId,
    optInNotifications,
    setLocation,
    setMessage,
    useSms,
    waitingLineId,
    metadata,
    locale,
  ]);

  return (
    <FreemiumLayout>
      <CircleContainer>
        <Circle />
      </CircleContainer>
      <div>
        <Text1>
          <FormattedMessage id="waiting_line_register" />
        </Text1>
      </div>
      <InputContainer>
        <InputHeader>
          <SwitchContactMethod onClick={toggleSms}>
            <FormattedMessage id={useSms ? 'waiting_line_register_use_email' : 'waiting_line_register_use_sms'} />
          </SwitchContactMethod>
        </InputHeader>
        {!useSms ? (
          <EmailInput
            disabled={loading}
            type="email"
            placeholder={formatMessage({ id: 'placeholder_email_address' })}
            value={communicationAddress}
            onChange={(event) => setCommunicationAddress(event.target.value)}
          />
        ) : (
          <TelInput
            disabled={loading}
            onlyCountries={['CA', 'US']}
            defaultCountry="CA"
            placeholder={formatMessage({ id: 'placeholder_sms_number' })}
            value={communicationAddress}
            onChange={(newValue) => setCommunicationAddress(newValue)}
          />
        )}
        <ErrorMessage>
          {formError && <FormattedMessage id={formError} />}
        </ErrorMessage>
      </InputContainer>
      <OptInCheckBoxContainer>
        <OptInLabel
          labelPlacement="start"
          control={(
            <ContrastCheckBox
              checked={optInNotifications}
              onChange={(_, checked) => setOptInNotifications(checked)}
            />
          )}
          label={<FormattedMessage id="cart_communication_opt_in_notifications" />}
        />
      </OptInCheckBoxContainer>
      {loading ? (
        <SpinnerContainer>
          <CircularProgress size="60px" />
        </SpinnerContainer>
      ) : (
        <RoundedContainedButton onClick={register}>
          <FontAwesomeIcon icon={faArrowRight} size="xl" />
        </RoundedContainedButton>
      )}
    </FreemiumLayout>
  );
}

export default Register;
