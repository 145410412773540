import { AdvancedImage, responsive } from '@cloudinary/react';
import {
  Button,
  Dialog,
  dialogClasses,
  Typography,
} from '@mui/material';
import { motion } from 'framer-motion';
import styled from 'styled-components';

export function hexToRgbString(hex: string) {
  let color = hex;
  if (hex.length === 4) {
    color = [...hex.replace('#', '')].reduce((acc, value) => `${acc}${value}${value}`, '#');
  }
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})/i.exec(color)!;
  return `${parseInt(result[1], 16)} ${parseInt(result[2], 16)} ${parseInt(result[3], 16)}`;
}

export const Background = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.palette.background.default};
`;

export const Centered = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const CenteredContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 1rem;
  padding: 0 16px;

  @media print {
    padding: 0;
    gap: 0;
    flex: 0;
  }
`;

export const ResponsiveCloudImg = styled(AdvancedImage).attrs({
  plugins: [responsive({ steps: [400, 800, 1200] })],
})`
  user-select: auto;
`;

export const EventLogo = styled(ResponsiveCloudImg)`
  max-width: 100%;
  height: 22svh;
  object-fit: cover;
  align-self: center;
`;

export const StyledDialog = styled(Dialog)`
  & .${dialogClasses.paper} {
    border-radius: 16px;
    padding: 16px;
  }
`;

export const BaseButton = styled(motion.button).attrs(() => ({
  whileTap: { scale: 0.95, opacity: 0.6 },
  whileHover: { opacity: 0.8 },
}))`
  border-radius: 0.5em;
  border: none;
  cursor: pointer;
  user-select: none;
  opacity: 1;
  font-family: Montserrat, sans-serif;
  font-weight: bold;

  &:disabled {
    cursor: none;
    pointer-events: none;
    opacity: 0.6;
  }

  @media print {
    display: none;
  }
`;

export const PrimaryButton = styled(BaseButton)`
  font-size: ${({ theme }) => theme.fontSizes.big};
  background-color: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.primary.contrastText};
  padding: 1em 2em;
  text-transform: uppercase;
`;

export const SmallPrimaryButton = styled(PrimaryButton)`
  font-size: ${({ theme }) => theme.fontSizes.normal};
  padding: 0.5em 1em;
`;

export const SecondaryButton = styled(PrimaryButton)`
  background-color: ${({ theme }) => theme.palette.secondary.main};
  color: ${({ theme }) => theme.palette.secondary.contrastText};
`;

export const DangerButton = styled(PrimaryButton)`
  background-color: ${({ theme }) => theme.palette.error.main};
  color: ${({ theme }) => theme.palette.error.contrastText};
`;

export const OutlinedPrimaryButton = styled(PrimaryButton)`
  background-color: transparent;
  border: 2px solid ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.primary.main};
  margin: -2px;
`;

export const OutlinedSecondaryButton = styled(OutlinedPrimaryButton)`
  border-color: ${({ theme }) => theme.palette.secondary.main};
  color: ${({ theme }) => theme.palette.secondary.main};
`;

export const ActionButton = styled(BaseButton)`
  background-color: transparent;
  border: 1px solid ${({ theme }) => theme.palette.custom.lightGray};
  padding: 0.65em 1em;
`;

export const SecondaryActionButton = styled(ActionButton)`
  color: ${({ theme }) => theme.palette.secondary.main};
  border: 2px solid ${({ theme }) => theme.palette.custom.secondaryAccent.main};
  outline: 2px solid rgba(${({ theme }) => hexToRgbString(theme.palette.custom.secondaryAccent.main)} / 40%);
`;

export const PrimaryLinkButton = styled(BaseButton)`
  color: ${({ theme }) => theme.palette.primary.main};
  text-decoration: underline;
  background-color: transparent;

  &:hover {
    text-decoration: none;
  }
`;

export const SecondaryLinkButton = styled(PrimaryLinkButton)`
  color: ${({ theme }) => theme.palette.secondary.main};
`;

export const RoundedContainedButton = styled(Button).attrs({
  variant: 'contained',
  disableRipple: true,
})`
  border-radius: 50%;
  height: 60px;
  min-height: 60px;
  width: 60px;
  min-width: 0;
  background-color: #fff;
  color: ${({ theme }) => theme.palette.secondary.main};
  box-shadow: 0 0 10px 0 rgb(0 0 0 / 50%);

  &:hover {
    opacity: 0.8;
    background-color: #fff;
  }
`;

export const Text: any = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.primary};
`;

export const ErrorMessage = styled(Text)`
  color: red;
  margin: 0.5em 0;
`;

export const FlexCenteredContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;
