import { SessionType } from 'lib/graphql/graphql';
import { createContext } from 'react';

export interface Order {
  amount: number
  type: SessionType
  transactionId?: string
  waitingLineId: string
}

export interface ITicketingContext {
  order?: Order
  clearOrder: () => void
  checkout: (order: Order) => void
  removeItem: (waitingLineId: string) => void
  updateOrder: (order: Partial<Order>) => void
}

export const defaultTicketingContext: ITicketingContext = {
  checkout: () => {},
  clearOrder: () => {},
  removeItem: () => {},
  updateOrder: () => {},
};

export const TicketingContext = createContext(defaultTicketingContext);
