import { faEnvelope, faMessageDots } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ContactInfoType } from 'lib/graphql/graphql';
import { CardButtons, CardButtonSpacer } from 'modules/Cart/styles';
import useCart from 'providers/CartProvider/useCart';
import { FormattedMessage } from 'react-intl';
import { OutlinedSecondaryButton, PrimaryButton } from 'styles/styles';

import {
  ColoredCheckbox,
  CommunicationChoice,
  CommunicationChoices,
  CommunicationChoiceTitle,
  CommunicationDescriptionText,
  OptInCheckBoxContainer,
  OptInLabel,
} from './styles';

interface CommunicationProps {
  onBack: () => void
  onNext: () => void
}

function Communication(props: CommunicationProps) {
  const { onBack, onNext } = props;

  const {
    communication,
    optInNotifications,
    setCommunication,
    setOptInNotifications,
  } = useCart();

  return (
    <>
      <CommunicationDescriptionText>
        <FormattedMessage id="cart_communication_description" />
      </CommunicationDescriptionText>
      <CommunicationChoices>
        <CommunicationChoice
          key={ContactInfoType.Email}
          $selected={communication === ContactInfoType.Email}
          onClick={() => setCommunication(ContactInfoType.Email)}
        >
          <FontAwesomeIcon icon={faEnvelope} size="3x" />
          <CommunicationChoiceTitle>
            <FormattedMessage id="cart_email" />
          </CommunicationChoiceTitle>
        </CommunicationChoice>
        <CommunicationChoice
          key={ContactInfoType.Phone}
          $selected={communication === ContactInfoType.Phone}
          onClick={() => setCommunication(ContactInfoType.Phone)}
        >
          <FontAwesomeIcon icon={faMessageDots} size="3x" />
          <CommunicationChoiceTitle>
            <FormattedMessage id="cart_sms" />
          </CommunicationChoiceTitle>
        </CommunicationChoice>
      </CommunicationChoices>
      <CardButtonSpacer />
      <OptInCheckBoxContainer>
        <OptInLabel
          labelPlacement="start"
          control={(
            <ColoredCheckbox
              checked={optInNotifications}
              onChange={(_, checked) => setOptInNotifications(checked)}
            />
          )}
          label={<FormattedMessage id="cart_communication_opt_in_notifications" />}
        />
      </OptInCheckBoxContainer>
      <CardButtonSpacer />
      <CardButtons>
        <OutlinedSecondaryButton onClick={onBack}>
          <FormattedMessage id="general_back" />
        </OutlinedSecondaryButton>
        <PrimaryButton onClick={onNext}>
          <FormattedMessage id="general_next" />
        </PrimaryButton>
      </CardButtons>
    </>
  );
}

export default Communication;
